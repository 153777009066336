<template>
  <div>
    <!-- Hero -->
    <base-page-heading
      title="Cotización"
      :subtitle="`| ${nuevaCotizacion ? 'Nueva': 'Editar'} cotización`"
      class="heading-block"
    />    

    <div>
      <base-block rounded title="">
        <div class="block-content font-size-sm">
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-row>
              <b-col sm="7">
                <b-row class="ml-4 mt-1">
                  <b-col sm="2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Cotización : <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="form.cotizacion_id"
                          disabled
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>  
                  <b-col sm="6">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        Cliente : <span class="text-danger">*</span>
                      </template>
                      <select class="form-control" @change="ChangePreCotizacion($event)" v-model="form.client_id" :disabled="nuevaCotizacion ? false: true">
                        <option :value="data.id" v-for="(data, index) in clientes" :key="index">
                          {{ data.nombre_comercial }}
                        </option>
                      </select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        Estado de la cotización: <span class="text-danger">*</span>
                      </template>
                      <select v-model="form.estado_cotizacion" class="form-control" :disabled="form.estado_cotizacion === 'aceptado' ? 'disabled' : null">
                        <option value="seguimiento">SEGUIMIENTO</option>
                        <option value="aceptado">ACEPTADO</option>
                        <option value="rechazado">RECHAZADO</option>
                        <option value="facturado">FACTURADO</option>
                      </select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="ml-4 mt-2">
                  <b-col sm="6">
                    <b-form-group label-for="username">
                      <template #label>
                          Comentario para esta cotización
                      </template>
                      <b-form-textarea
                        rows="3"
                        max-rows="6"
                        v-model="form.comentarios"
                        size="sm"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="ml-4 mt-3">
                  <b-col sm="12">
                    <h3 class="text-danger">Instrucciones para generar información para la cotización</h3>
                    <ol type="1">
                      <li>Seleccione un cliente a quien se le va a realizar la cotización. Cuando lo selecciona automáticamente se genera un número de cotización.</li>
                      <li>De click en el botón "Agregar NP" para agregar un elemento de producto terminado.</li>
                      <li>Una vez que agregue un producto terminado, edite su información (como número de piezas y número de parte) y seleccione el icono de guardar de ese elemento.</li>
                      <li>Solo cuando haya guardado el producto terminado en custión, podrá agregar items de la lista "Materia Prima o Servicio", si lo hace antes le mostrará un error.</li>
                      <li>Agrege elementos de la lista Materia Prima o Servicio. Cuando los agregue, edite y guarde sus cambios (dando click en el icono de guardar de cada elemento), antes de continuar agregando otro elemento a la lista.</li>
                      <li>Utilice el botón "Borrar NP" para borrar el elemento de producto terminado en cuestión. Esta operación se encargará de borrar todo el contenido de ese elemento.</li>
                    </ol>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="4" offset-md="1" class="rounded border p-4">
                <FileSystem v-if="quote_id != ''" view="cotizacion" :idItemView="quote_id" :files="files"></FileSystem>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-button variant="primary" class="mr-1" @click="addNP">Agregar NP</b-button>
              </b-col>

              <b-row>
                  <!-- MODAL -->
                  <b-modal
                    id="modal-items-pf"
                    size="xl"
                    body-class="p-0"
                    hide-footer
                    hide-header
                    >
                    <div class="block block-rounded block-themed block-transparent mb-0">
                      <div class="block-header bg-primary-dark">
                        <h3 class="block-title">
                          <i class="fa fa-user mr-1"></i> Lista
                        </h3>
                          <div class="block-options">
                            <i style="cursor:pointer;" class="fa fa-fw fa-times" @click="$bvModal.hide('modal-items-pf')"></i>
                          </div>
                      </div>
                      <div class="block-content font-size-sm">
                        <b-row>
                          <b-col offset-sm="9" sm="2">
                            <b-form inline>
                              <b-form-group label-for="no_cliente">
                                <template>
                                  Buscar:
                                </template>
                                <b-form-input
                                  v-model="searchTerm"
                                  size="sm"
                                ></b-form-input>
                              </b-form-group>
                            </b-form>
                          </b-col>
                        </b-row>
                        <div class="table-responsive">
                          <template>
                            <b-table 
                              @row-clicked="SelectItemsProductoTerminado"
                              :items="productoTerminadoListFilter"
                              :fields="fields_producto_terminado_list"
                              :current-page="currentPage"
                              :per-page="perPage"
                              responsive
                              striped
                              hover>
                            </b-table>
                          </template>
                        </div>
                      </div>
                      <b-row>
                        <b-col sm="7" md="2" class="my-1">
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                          ></b-pagination>
                        </b-col>
                      </b-row>
                      <div class="block-content block-content-full text-right border-top">
                        <b-button
                          variant="alt-primary"
                          class="mr-1"
                          @click="$bvModal.hide('modal-items-pf')"
                        >Cerrar</b-button>
                      </div>
                    </div>
                  </b-modal>
                  <!-- FIN MODAL -->
                </b-row>
            </b-row>
            
            <br />

            <div v-for="(item_producto_terminado, index_Q_P_T) in selected_list_items_producto_terminado" :key="item_producto_terminado.q_f_p_id" class="mt-2">
              <div class="border radius p-2">
                <b-row class="mt-2">
                  <b-col sm="2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        # de partida:
                      </template>
                      <b-form-input
                        :value='index_Q_P_T+1'
                        disabled
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        # de piezas: <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        v-b-tooltip.hover title="Escriba el número de piezas"
                        v-model='item_producto_terminado.no_piezas'
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        # de parte: <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        v-b-tooltip.hover title="Escriba el número de parte"
                        v-model='item_producto_terminado.no_parte'
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col offset-sm="2" sm="3">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          SELECCIONE MATERIA PRIMA O SERVICIO 
                      </template>
                      <select :disabled="item_producto_terminado.no_piezas==''" v-model="select_option" @change="Option($event,index_Q_P_T)" class="form-control">
                          <!--<option value="1">SERVICIO</option>-->
                          <option value="2">MATERIA PRIMA</option>
                          <option value="3">MAQUINARIA</option>
                          <option value="4">OPERADOR</option>
                      </select>
                      <span  style="color:red;" v-if="item_producto_terminado.no_piezas==''">Escriba el # de piezas primero</span>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- MODAL -->
                  <b-modal
                    id="modal-items"
                    size="xl"
                    body-class="p-0"
                    hide-footer
                    hide-header
                    >
                    <div class="block block-rounded block-themed block-transparent mb-0">
                      <div class="block-header bg-primary-dark">
                        <h3 class="block-title">
                          <i class="fa fa-user mr-1"></i> Lista
                        </h3>
                          <div class="block-options">
                            <i style="cursor:pointer;" class="fa fa-fw fa-times" @click="$bvModal.hide('modal-items')"></i>
                          </div>
                      </div>
                      <div class="block-content font-size-sm">
                        <b-row>
                          <b-col offset-sm="9" sm="2">
                            <b-form inline>
                              <b-form-group label-for="no_cliente">
                                <template>
                                  Buscar:
                                </template>
                                <b-form-input
                                  v-model="searchTerm"
                                  size="sm"
                                ></b-form-input>
                              </b-form-group>
                            </b-form>
                          </b-col>
                        </b-row>
                        <div class="table-responsive">
                          <template v-if="select_option==1">
                            <b-table  
                              @row-clicked="SelectItemsServicios"
                              :items="servicios"
                              :fields="fields_servicios"
                              :current-page="currentPage"
                              :per-page="perPage"
                              responsive
                              striped
                              hover>
                            </b-table>
                          </template>
                          <template v-else-if="select_option==2">
                          <b-table 
                              @row-clicked="SelectItemsMateriaP"
                              :items="materiapFilter"
                              :fields="fields_materiap"
                              :current-page="currentPage"
                              :per-page="perPage"
                              responsive
                              striped
                              hover>
                            </b-table>
                          </template>
                          <template v-else-if="select_option==3">
                            <b-table 
                              @row-clicked="SelectItemsMaquinaria"
                              :items="maquinariasListFilter"
                              :fields="fields_maquinaria_list"
                              :current-page="currentPage"
                              :per-page="perPage"
                              responsive
                              striped
                              hover>
                            </b-table>
                          </template>
                          <template v-else-if="select_option==4">
                            <b-table
                              @row-clicked="SelectItemsOperadores"
                              :items="operadoresListFilter"
                              :fields="fields_operador_list"
                              :current-page="currentPage"
                              :per-page="perPage"
                              responsive
                              striped
                              hover>
                            </b-table>
                          </template>
                          <template v-else>
                            <b-table 
                              @row-clicked="SelectItemsOperadores"
                              :items="productoTerminadoList"
                              :fields="fields_producto_terminado_list"
                              :current-page="currentPage"
                              :per-page="perPage"
                              responsive
                              striped
                              hover>
                            </b-table>
                          </template>
                        </div>
                      </div>
                      <b-row>
                        <b-col sm="7" md="2" class="my-1">
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                          ></b-pagination>
                        </b-col>
                      </b-row>
                      <div class="block-content block-content-full text-right border-top">
                        <b-button
                          variant="alt-primary"
                          class="mr-1"
                          @click="$bvModal.hide('modal-items')"
                        >Cerrar</b-button>
                      </div>
                    </div>
                  </b-modal>
                  <!-- FIN MODAL -->
                </b-row>

                <b-row>
                  <!-- === MATERIA PRIMA === -->
                  <div class="container">
                    <b-row class="mt-4">
                      <div class="container">
                        <div class="row">
                          <div class="col-5" id="linea">
                            <hr>
                          </div>
                          <div class="col-2" id="titulo">
                            <p>Materia Prima</p>
                          </div>
                          <div class="col-5" id="linea">
                            <hr>
                          </div>
                        </div>
                      </div>
                    </b-row>
                    <b-row>
                      <b-col sm="12">
                        <div class="accordion" role="tablist">
                          <b-card no-body class="mb-1" v-for="(data, index_Q_P_T_S_M_P) in item_producto_terminado.materia_prima[0]" :key="data.index_Q_P_T_S_M_P">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                              <b-button id="button_accordion" block v-b-toggle="'accordion-'+index_Q_P_T+'-'+index_Q_P_T_S_M_P" variant="info">{{data.materia_prima_codigo ? data.materia_prima_codigo : data.codigo}} - {{data.materia_prima_descripcion ? data.materia_prima_descripcion : data.descripcion}}</b-button>
                              <i class="fa fa-trash" @click="DeletedSelectMateriaP(data.q_f_p_s_m_p_id,index_Q_P_T_S_M_P,index_Q_P_T)" style="cursor: pointer;position: absolute;float: left;left: 90%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                              <i class="fa fa-save" @click="data.q_f_p_s_m_p_id > 0 ? UpdateItemMateriaPrima(index_Q_P_T, index_Q_P_T_S_M_P, item_producto_terminado.q_f_p_id, data.q_f_p_s_m_p_id) : SaveItemMateriaPrima(index_Q_P_T, index_Q_P_T_S_M_P, item_producto_terminado.q_f_p_id)" style="cursor: pointer;position: absolute;float: left;left: 93%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                            </b-card-header>
                            <b-collapse :id="'accordion-'+index_Q_P_T+'-'+index_Q_P_T_S_M_P" accordion="my-accordion" role="tabpanel">
                              <b-card-body>
                                <br><br>
                                <b-row class="ml-4 mt-12">
                                  <b-col sm="12">
                                    <b-row style="padding: 1%;width: 99%;border-width: 1px;border-style: solid;border-color: #d0d0d0;border-radius: 5px;"> 
                                      <b-col sm="12">
                                        <b-form-group>
                                          <template #label>
                                            ESPECIFICACIONES
                                          </template>
                                        </b-form-group>
                                      </b-col>
                                      <b-row class="ml-1 w-100">
                                        <b-col sm="2">
                                          <b-form-group>
                                            <template #label>
                                              Código
                                            </template>
                                            <b-form-input
                                              disabled
                                              size="sm"
                                              v-model="data.codigo"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col> 
                                        <b-col sm="3">
                                          <b-form-group>
                                            <template #label>
                                              Descripción
                                            </template>
                                            <b-form-input
                                              disabled
                                              v-model="data.descripcion"
                                              size="sm"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col> 
                                        <b-col sm="2">
                                          <b-form-group>
                                            <template #label>
                                              Largo Estándar {{data.largo}}
                                            </template>
                                            <b-form-input
                                              disabled
                                              v-model="data.largo"
                                              size="sm"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col> 
                                        <b-col sm="2">
                                          <b-form-group>
                                            <template #label>
                                              Largo Estándar (mm)
                                            </template>
                                            <b-form-input
                                              disabled
                                              :value="data.largo*10"
                                              size="sm"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="2">
                                          <b-form-group>
                                            <template #label>
                                              Familia
                                            </template>
                                            <b-form-input
                                              disabled
                                              v-model="data.materia_prima_family_name"
                                              size="sm"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row class="ml-1 mt-3 w-100">
                                        <b-col sm="2">
                                          <b-form-group>
                                            <template #label>
                                              Peso teórico
                                            </template>
                                            <b-form-input
                                              disabled
                                              v-model="data.peso_teorico"
                                              size="sm"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col sm="2">
                                          <b-form-group>
                                            <template #label>
                                              Peso x mm<sup>2</sup>
                                            </template>
                                            <b-form-input
                                              disabled
                                              v-model="data.peso_mm2"
                                              size="sm"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <br><br>
                                      <b-col sm="12" class="mt-4">
                                        <b-form-group>
                                          <template #label>
                                            COSTOS
                                          </template>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2">
                                        <b-form-group label-for="pv">
                                          <template #label>
                                            Cotizar por PV Kilo
                                          </template>
                                          <b-input-group size="sm" prepend="$" append=".00">
                                            <b-form-input
                                                v-model="data.pv"
                                                size="sm"
                                              ></b-form-input>
                                          </b-input-group>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2">
                                        <b-form-group label-for="costo">
                                          <template #label>
                                            Costo
                                          </template>
                                          <b-input-group size="sm" prepend="$" append=".00">
                                            <b-form-input
                                              v-model="data.costo"
                                              size="sm"
                                            ></b-form-input>
                                          </b-input-group>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2">
                                        <b-form-group label-for="margen">
                                          <template #label>
                                            Margen
                                          </template>

                                          <b-input-group size="sm" append="%">

                                            <b-form-input
                                              v-if="cotizarPorPV" 
                                              v-model="data.margen"
                                              :value="data.margen = Number.parseFloat((((data.pv - data.costo)*100)/data.costo)).toFixed(2)"
                                              size="sm"
                                              disabled
                                            ></b-form-input>

                                          </b-input-group>

                                          <!--data.margen ? data.margen : data.margen_from_services ? data.margen = data.margen_from_services : data.service_type.margen ? data.margen = data.service_type.margen : data.margen =-->


                                          <b-form-input
                                            v-if="!cotizarPorPV"
                                            v-model="data.margen"
                                            :value="data.margen ? data.margen : data.margen_from_services ? data.margen = data.margen_from_services : data.service_type.margen ? data.margen = data.service_type.margen : data.margen = -1"
                                            size="sm"
                                          ></b-form-input>

                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="1" v-if="false">
                                        <b-form-group label-for="costo">
                                          <template #label>
                                            Costo
                                          </template>
                                          <b-form-input
                                            :value="data.costo ? data.costo : data.costo_ferrebaztan ? data.costo = data.costo_ferrebaztan : data.service_type.costo_ferrebaztan ? data.costo = data.service_type.costo_ferrebaztan : -1"
                                            v-model="data.costo"
                                            size="sm"
                                            :disabled="cotizarPorPV"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="1" v-if="false">
                                        <b-form-group label-for="margen">
                                          <template #label>
                                            Margen
                                          </template>

                                          <b-form-input
                                            v-if="cotizarPorPV" 
                                            v-model="data.margen"
                                            :value="data.margen = Number.parseFloat((((data.pv - data.costo)*100)/data.costo)).toFixed(2)"
                                            size="sm"
                                            disabled
                                          ></b-form-input>

                                          <!--data.margen ? data.margen : data.margen_from_services ? data.margen = data.margen_from_services : data.service_type.margen ? data.margen = data.service_type.margen : data.margen =-->


                                          <b-form-input
                                            v-if="!cotizarPorPV"
                                            v-model="data.margen"
                                            :value="data.margen ? data.margen : data.margen_from_services ? data.margen = data.margen_from_services : data.service_type.margen ? data.margen = data.service_type.margen : data.margen = -1"
                                            size="sm"
                                          ></b-form-input>

                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="4" v-if="false">
                                        <b-row>
                                          <b-col sm="1">
                                            <b-form-group>
                                              <template #label>
                                                &nbsp;
                                              </template>
                                              <b-input-group size="sm" prepend="">
                                                <input type="checkbox" @click="cotizarPorPV=!cotizarPorPV" :checked="cotizarPorPV" />
                                              </b-input-group>
                                            </b-form-group>
                                          </b-col>
                                          <b-col sm="6">
                                            <b-form-group label-for="pv">
                                              <template #label>
                                                Cotizar por PV Kilo
                                              </template>
                                              <b-input-group size="sm" prepend="$">
                                                <b-form-input
                                                  v-if="!cotizarPorPV"
                                                  :value="data.pv ? data.pv = ((((data.costo*data.margen)/100))+(parseInt(data.costo))) : data.pv = data.pv_from_services"
                                                  v-model="data.pv"
                                                  size="sm"
                                                  disabled
                                                ></b-form-input>
                                                <b-form-input
                                                    v-if="cotizarPorPV"
                                                    :value="data.pv ? data.pv : data.pv_from_services ? data.pv = data.pv_from_services : data.service_type.pv ? data.pv = data.service_type.pv : data.pv = -1"
                                                    v-model="data.pv"
                                                    size="sm"
                                                  ></b-form-input>
                                              </b-input-group>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                    <br><br>
                                    <b-row style="position:relative;"> 
                                      <b-col sm="8" >
                                        <b-row>
                                          <b-col sm="4" >
                                            <!-- seccion 1 -->
                                            <b-row>
                                              <b-col sm="12" >
                                                <b-form-group>
                                                  <template #label>
                                                    LAMINAS/PLACAS A COTIZAR
                                                  </template>
                                                </b-form-group>
                                              </b-col>
                                              <b-col sm="8" >
                                                <b-form-group label-for="largo_solicitado">
                                                  <template #label>
                                                    # Laminas/Placas
                                                  </template>
                                                  <b-form-input
                                                    v-model="data.no_laminas_placas"
                                                    v-b-tooltip.hover title="Escriba el número de laminas/placas"
                                                    size="sm"
                                                  ></b-form-input>
                                                </b-form-group>
                                              </b-col>
                                            </b-row>
                                          </b-col>
                                          <b-col sm="8" >
                                            <b-row>
                                              <!-- seccion 2 -->
                                              <b-col sm="12">
                                                <b-form-group>
                                                  <template #label>
                                                    MATERIAL POR CORTE A COTIZAR
                                                  </template>
                                                </b-form-group>
                                              </b-col>
                                              <b-row>
                                                <b-col sm="6">
                                                  <b-form-group label-for="largo_solicitado">
                                                    <template #label>
                                                        Largo Solicitado (mm)
                                                    </template>
                                                    <b-form-input
                                                      v-b-tooltip.hover title="Escriba el largo solicitado (mm)"
                                                      id="largo_solicitado"
                                                      v-model="data.largo_solicitado"
                                                      size="sm"
                                                    ></b-form-input>
                                                  <!--    Precio:
                                                      $ {{ ((data.largo_solicitado * data.ancho) * data.peso_mm2) * data.pv }}
                                                  --></b-form-group>
                                                </b-col>
                                                <b-col sm="6">
                                                  <b-form-group label-for="ancho_solicitado">
                                                    <template #label>
                                                        Ancho solicitado (mm)
                                                    </template>
                                                    <b-form-input
                                                      v-b-tooltip.hover title="Escriba el ancho solicitado (mm)"
                                                      id="largo_solicitado"
                                                      v-model="data.ancho_solicitado"
                                                      size="sm"
                                                    ></b-form-input>
                                                  </b-form-group>
                                                </b-col>    
                                              </b-row>
                                              <!-- fin seccion 2 -->
                                            </b-row>
                                          </b-col>
                                        </b-row>

                                        <b-col sm="12" class="mt-4">
                                          <b-form-group>
                                            <template #label class="text-danger">
                                                TOTAL KILOS
                                            </template>
                                          </b-form-group>
                                        </b-col>

                                        <b-row>
                                          <b-col sm="4">
                                            <b-form-group label-for="kilos_solicitados">
                                              <template #label>
                                                Kilos Totales
                                              </template>
                                              <b-form-input
                                                disabled
                                                :value="data.kilos_totales = data.no_laminas_placas * data.peso_teorico"
                                                size="sm"
                                              ></b-form-input>
                                            </b-form-group>
                                          </b-col>

                                            + 

                                          <b-col sm="4">
                                            <b-form-group label-for="kilos_solicitados">
                                              <template #label>
                                                Kilos Solicitados
                                              </template>
                                              <b-form-input
                                                disabled
                                                :value="data.kilos_solicitados = (data.largo_solicitado * data.ancho_solicitado) * data.peso_mm2"
                                                size="sm"
                                              ></b-form-input>
                                            </b-form-group>
                                          </b-col> 

                                            =

                                          <b-col sm="2" class="ml-4">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Total Kilos
                                              </template>
                                              <b-form-input
                                                :value="data.total_de_kilos = data.kilos_totales + data.kilos_solicitados"
                                                disabled
                                                size="sm"
                                              ></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>

                                        <b-row class="mt-4">
                                          <b-col sm="6">
                                            <b-form-group label-for="kilos_solicitados">
                                              <template #label>
                                                Comentarios
                                              </template>
                                              <b-form-textarea
                                                v-model="data.comentarios"
                                                size="sm"
                                              ></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </b-col>

                                      <div class="v-line"></div>

                                      <b-col sm="4" >
                                        <b-col sm="12">
                                          <b-form-group>
                                            <template #label>
                                              COSTOS TOTALES
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                        <b-row class="ml-4 mt-1">
                                          <b-col sm="5">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Total
                                              </template>
                                              $ {{ data.total_dinero = parseFloat((data.kilos_totales + data.kilos_solicitados) * data.pv).toFixed(2) }}
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row class="ml-4 mt-1">
                                          <b-col sm="5">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Total x Pza
                                              </template>
                                              $ {{ data.total_x_pieza = ( isFinite( ( data.kilos_totales + data.kilos_solicitados ) * data.pv / item_producto_terminado.no_piezas) ) ? parseFloat((data.kilos_totales + data.kilos_solicitados) * data.pv / item_producto_terminado.no_piezas).toFixed(2) : 0 }}
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row class="ml-4 mt-1">
                                          <b-col sm="5">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Descuento %
                                              </template>
                                              <b-form-input
                                                v-model="data.descuento"
                                                size="sm"
                                              ></b-form-input>
                                              <select class="form-control" v-model="data.descuento" v-if="false">
                                                <option value="0.00" :selected="[ (data.descuento) ? 'selected':'' ]">0%</option>
                                                <option value="5.00">5%</option>
                                                <option value="10.00">10%</option>
                                                <option value="20.00">20%</option>
                                              </select>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row class="ml-4 mt-4">
                                          <b-col sm="8">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Total (con/sin Descuento)
                                              </template>
                                              $ {{  data.total_con_descuento == 0 ? data.total_con_descuento = data.total_dinero : data.total_con_descuento = (data.total_dinero - ((data.total_dinero * data.descuento)/100)).toFixed(2) }}
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </b-col>                                                           
                                    </b-row>
                                    <br><br>
                                    <br><br>
                                  </b-col>
                                </b-row>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <!-- === FIN DE MATERIA PRIMA === -->

                  <!-- === MAQUINARIA === -->
                  <div class="container">
                    <b-row class="mt-4">
                      <div class="container">
                        <div class="row">
                          <div class="col-5" id="linea"><hr></div>
                          <div class="col-2" id="titulo"><p>Maquinaria</p></div>
                          <div class="col-5" id="linea"><hr></div>
                        </div>
                      </div>
                    </b-row>
                    <b-row>
                      <b-col sm="12">
                        <div class="accordion" role="tablist">
                          <b-card no-body class="mb-1" v-for="(data, index_Q_P_T_M) in item_producto_terminado.maquinaria[0]" :key="data.q_f_p_m_id">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                              <b-button id="button_accordion" block v-b-toggle="'accordion-maquinaria-'+index_Q_P_T+'-'+index_Q_P_T_M" variant="info">{{data.nombre_maquina}}</b-button>
                              <i class="fa fa-trash" @click="DeleteSelectedMaquinaria(data.q_f_p_m_id, index_Q_P_T_M, index_Q_P_T)" style="cursor: pointer;position: absolute;float: left;left: 90%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                              <i class="fa fa-save" @click="data.q_f_p_m_id > 0 ? UpdateItemMaquinaria(index_Q_P_T, index_Q_P_T_M, item_producto_terminado.q_f_p_id, data.q_f_p_m_id) : SaveItemMaquinaria(index_Q_P_T, index_Q_P_T_M, item_producto_terminado.q_f_p_id)" style="cursor: pointer;position: absolute;float: left;left: 93%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                            </b-card-header>
                            <b-collapse :id="'accordion-maquinaria-'+index_Q_P_T+'-'+index_Q_P_T_M" accordion="my-accordion-maquinaria" role="tabpanel">
                              <b-card-body>
                                <br><br>
                                <b-row class="ml-4 mt-12">
                                  <b-col sm="12">
                                    <b-row style="padding: 1%;width: 99%;border-width: 1px;border-style: solid;border-color: #d0d0d0;border-radius: 5px;"> 
                                      <b-col sm="12">
                                        <b-form-group>
                                          <template #label>
                                            ESPECIFICACIONES
                                          </template>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2">
                                        <b-form-group>
                                          <template #label>
                                            Número de máquina
                                          </template>
                                          <b-form-input
                                            disabled
                                            v-model="data.numero_maquina"                                                                                
                                            size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col> 
                                      <b-col sm="3">
                                        <b-form-group>
                                          <template #label>
                                            Descripción
                                          </template>
                                          <b-form-input
                                            disabled
                                            v-model="data.nombre_maquina"                                                                                
                                            size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col> 
                                      <br><br>
                                      <b-col sm="12" class="mt-4">
                                        <b-form-group>
                                          <template #label>
                                            COSTOS
                                          </template>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2">
                                        <b-form-group label-for="kilos_solicitados">
                                          <template #label>
                                            Costo x hora
                                          </template>
                                          <b-form-input
                                            :value="data.costo ? data.costo : data.costo = data.costo_hora"
                                            :other="data.pv ? data.pv : data.pv = data.costo_hora"
                                            v-model="data.costo"
                                            size="sm"
                                            disabled
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="1">
                                        <b-form-group label-for="kilos_solicitados">
                                          <template #label>
                                            Margen
                                          </template>

                                          <b-form-input
                                            v-if="cotizarPorPVServicios"
                                            :value="data.margen = Number.parseFloat((((data.pv - data.costo)*100)/data.costo)).toFixed(2)"
                                            v-model="data.margen"
                                            size="sm"
                                            :disabled="cotizarPorPVServicios"
                                          ></b-form-input>

                                          <b-form-input
                                            v-if="!cotizarPorPVServicios"
                                            v-model="data.margen"
                                            size="sm"
                                          ></b-form-input>

                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2">
                                        <b-form-group label-for="kilos_solicitados">
                                          <template #label>
                                            Cotizar por PV
                                          </template>
                                          <b-row>
                                            <b-col sm="1">
                                              <input type="checkbox" @click="cotizarPorPVServicios=!cotizarPorPVServicios" :checked="cotizarPorPVServicios" />
                                            </b-col>

                                            <b-col sm="9">
                                              <b-form-input
                                                v-if="!cotizarPorPVServicios"
                                                :value="data.pv = Number.parseFloat(((((data.costo*data.margen)/100))+(parseFloat(data.costo)))).toFixed(2)"
                                                v-model="data.pv"
                                                size="sm"
                                                disabled
                                              ></b-form-input>

                                              <b-form-input
                                                v-if="cotizarPorPVServicios"
                                                :value="data.pv ? data.pv : data.pv = data.costo_hora"
                                                v-model="data.pv"
                                                ref="el"
                                                size="sm"
                                              ></b-form-input>
                                            </b-col>
                                          </b-row>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                    <br><br>
                                    <br><br>
                                    <b-row class="position-relative"> 
                                      <b-col sm="8" >
                                        <b-row>
                                          <b-col sm="8" >
                                            <!-- seccion 1 -->
                                            <b-row>

                                              <b-col sm="12" >
                                                <b-form-group>
                                                  <template #label>
                                                    DATOS A COTIZAR
                                                  </template>
                                                </b-form-group>
                                              </b-col>

                                              <b-col sm="4" >
                                                <b-form-group label-for="largo_solicitado">
                                                  <template #label>
                                                    Minutos a utilizar
                                                  </template>
                                                  <b-form-input
                                                    :value="data.minutos ? data.minutos : data.minutos = 0"
                                                    v-model="data.minutos"
                                                    v-b-tooltip.hover title="Escriba el número de minutos"
                                                    size="sm"
                                                  ></b-form-input>
                                                </b-form-group>
                                              </b-col>

                                              <b-col sm="7">
                                                <b-form-group label-for="kilos_solicitados">
                                                  <template #label>
                                                    Comentarios
                                                  </template>
                                                  <b-form-textarea
                                                    v-model="data.comentarios"
                                                    size="sm"
                                                  ></b-form-textarea>
                                                </b-form-group>
                                              </b-col>
                                        
                                            </b-row>
                                          </b-col>
                                        </b-row>
                                      </b-col>
                                      <div class="v-line" style="height:100%"></div>
                                      <b-col sm="4" >
                                        <b-row>
                                          <b-col sm="12">
                                            <b-form-group>
                                              <template #label>
                                                COSTOS TOTALES
                                              </template>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row class="ml-4 mt-1">
                                          <b-col sm="5">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Total
                                              </template>
                                              $ {{ data.total_dinero = parseFloat((data.minutos / 60)  * data.pv).toFixed(2) }}
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row class="ml-4 mt-1">
                                          <b-col sm="5">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Total x Pza
                                              </template>
                                              $ {{ data.total_x_pieza = parseFloat(data.total_dinero / item_producto_terminado.no_piezas).toFixed(2) }}
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row class="ml-4 mt-1">
                                          <b-col sm="5">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                  Descuento % 
                                              </template>
                                              <b-form-input
                                                v-model="data.descuento"
                                                size="sm"
                                              ></b-form-input>
                                              <select class="form-control" v-model="data.descuento" v-if="false">
                                                <option value="0.00">0%</option>
                                                <option value="5.00">5%</option>
                                                <option value="10.00">10%</option>
                                                <option value="20.00">20%</option>
                                              </select>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row class="ml-4 mt-4">
                                          <b-col sm="8">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Total (con/sin Descuento)
                                              </template>
                                              $ {{  data.total_con_descuento == 0 ? data.total_con_descuento = data.total_dinero : data.total_con_descuento = (data.total_dinero - ((data.total_dinero * data.descuento)/100)).toFixed(2) }}
                                            </b-form-group>
                                          </b-col>  
                                        </b-row>  
                                      </b-col>
                                    </b-row>
                                    <br><br>
                                    <br><br>
                                  </b-col>
                                </b-row>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <!-- ===  FIN DE MAQUINARIA === -->

                  <!-- === OPERADORES === -->
                  <div class="container">
                    <b-row class="mt-4">
                      <div class="container">
                        <div class="row">
                          <div class="col-5" id="linea">
                            <hr>
                          </div>
                          <div class="col-2" id="titulo">
                            <p>Operadores</p>
                          </div>
                          <div class="col-5" id="linea">
                            <hr>
                          </div>
                        </div>
                      </div>
                    </b-row>
                    <b-row>
                      <b-col sm="12">
                        <div class="accordion" role="tablist">
                          <b-card no-body class="mb-1" v-for="(data, index_Q_P_T_O) in item_producto_terminado.operadores[0]" :key="data.q_f_p_o_id">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                              <b-button id="button_accordion" block v-b-toggle="'accordion-operadores-'+index_Q_P_T+'-'+index_Q_P_T_O" variant="info">Operador: {{data.nombre_completo}}</b-button>
                              <i class="fa fa-trash" @click="DeleteSelectedOperador(data.q_f_p_o_id, index_Q_P_T_O, index_Q_P_T)" style="cursor: pointer;position: absolute;float: left;left: 90%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                              <i class="fa fa-save" @click="data.q_f_p_o_id > 0 ? UpdateItemOperador(index_Q_P_T, index_Q_P_T_O, item_producto_terminado.q_f_p_id, data.q_f_p_o_id) : SaveItemOperador(index_Q_P_T, index_Q_P_T_O, item_producto_terminado.q_f_p_id)" style="cursor: pointer;position: absolute;float: left;left: 93%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                            </b-card-header>
                            <b-collapse :id="'accordion-operadores-'+index_Q_P_T+'-'+index_Q_P_T_O" accordion="my-accordion-operadores" role="tabpanel">
                              <b-card-body>
                                <br><br>
                                <b-row class="ml-4 mt-12">
                                  <b-col sm="12">
                                    <b-row style="padding: 1%;width: 99%;border-width: 1px;border-style: solid;border-color: #d0d0d0;border-radius: 5px;"> 
                                      <b-col sm="12">
                                        <b-form-group>
                                            <template #label>
                                                ESPECIFICACIONES
                                            </template>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2">
                                        <b-form-group>
                                            <template #label>
                                                Número de empleado
                                            </template>
                                            <b-form-input
                                                disabled
                                                v-model="data.no_empleado" 
                                                size="sm"
                                            ></b-form-input>
                                        </b-form-group>
                                      </b-col> 
                                      <b-col sm="4">
                                        <b-form-group>
                                          <template #label>
                                            Nombre del operador
                                          </template>
                                          <b-form-input
                                              disabled
                                              v-model="data.nombre_completo"
                                              size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col> 
                                      <br><br>
                                      <b-col sm="12" class="mt-4">
                                        <b-form-group>
                                          <template #label>
                                            COSTOS
                                          </template>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2">
                                        <b-form-group label-for="kilos_solicitados">
                                          <template #label>
                                              Salario por hora
                                          </template>
                                          <b-form-input
                                              :value="data.costo ? data.costo : data.costo = data.salario_hora"
                                              :other="data.pv ? data.pv : data.pv = data.salario_hora"
                                              v-model="data.costo"
                                              size="sm"
                                              disabled
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="1">
                                        <b-form-group label-for="kilos_solicitados">
                                          <template #label>
                                            Margen
                                          </template>

                                          <b-form-input
                                            v-if="cotizarPorPVOperadores"
                                            :value="data.margen = Number.parseFloat((((data.pv - data.costo)*100)/data.costo)).toFixed(2)"
                                            v-model="data.margen"
                                            size="sm"
                                            :disabled="cotizarPorPVOperadores"
                                          ></b-form-input>

                                          <b-form-input
                                            v-if="!cotizarPorPVOperadores"
                                            v-model="data.margen"
                                            size="sm"
                                            :disabled="cotizarPorPVOperadores"
                                          ></b-form-input>

                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2">
                                        <b-form-group label-for="kilos_solicitados">
                                          <template #label>
                                            Cotizar por PV
                                          </template>
                                          <b-row>
                                            <b-col sm="1">
                                              <input type="checkbox" @click="cotizarPorPVOperadores=!cotizarPorPVOperadores" :checked="cotizarPorPVOperadores" />
                                            </b-col>

                                            <b-col sm="9">
                                              <b-form-input
                                                v-if="!cotizarPorPVOperadores"
                                                :value="data.pv = Number.parseFloat(((((data.costo*data.margen)/100))+(parseFloat(data.costo)))).toFixed(2)"
                                                v-model="data.pv"
                                                size="sm"
                                                disabled
                                              ></b-form-input>

                                              <b-form-input
                                                v-if="cotizarPorPVOperadores"
                                                :value="data.pv ? data.pv : data.salario_hora"
                                                v-model="data.pv"
                                                size="sm"
                                              ></b-form-input>

                                            </b-col>


                                          </b-row>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                    <br><br>
                                    <br><br>
                                    <b-row class="position-relative"> 
                                      <b-col sm="8" >
                                        <b-row>
                                          <b-col sm="8" >
                                            <!-- seccion 1 -->
                                            <b-row>
                                              <b-col sm="12" >
                                                <b-form-group>
                                                  <template #label>
                                                    DATOS A COTIZAR
                                                  </template>
                                                </b-form-group>
                                              </b-col>
                                              <b-col sm="4" >
                                                <b-form-group label-for="largo_solicitado">
                                                  <template #label>
                                                      Minutos a utilizar
                                                  </template>
                                                  <b-form-input
                                                      v-model="data.minutos"
                                                      v-b-tooltip.hover title="Escriba el número de minutos"
                                                      size="sm"
                                                  ></b-form-input>
                                                </b-form-group>
                                              </b-col>
                                              <b-col sm="7">
                                                <b-form-group label-for="kilos_solicitados">
                                                  <template #label>
                                                    Comentarios
                                                  </template>
                                                  <b-form-textarea
                                                    v-model="data.comentarios"
                                                    size="sm"
                                                  ></b-form-textarea>
                                                </b-form-group>
                                              </b-col>
                                            </b-row>
                                          </b-col>
                                        </b-row>
                                      </b-col>
                                      <div class="v-line" style="height:100%"></div>
                                      <b-col sm="4" >
                                        <b-col sm="12">
                                          <b-form-group>
                                            <template #label>
                                              COSTOS TOTALES
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                        <b-row class="ml-4 mt-1">
                                          <b-col sm="5">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Total
                                              </template>
                                              $ {{ data.total_dinero = parseFloat((data.minutos/60) * data.pv).toFixed(2) }}
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row class="ml-4 mt-1">
                                          <b-col sm="5">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                  Total x Pza
                                              </template>
                                              $ {{ data.total_x_pieza = parseFloat(data.total_dinero / item_producto_terminado.no_piezas).toFixed(2) }}
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row class="ml-4 mt-1">
                                          <b-col sm="5">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Descuento %
                                              </template>
                                              <b-form-input
                                                v-model="data.descuento"
                                                size="sm"
                                              ></b-form-input>
                                              <select class="form-control" v-model="data.descuento" v-if="false">
                                                <option value="0.00" :selected="[ (data.descuento) ? 'selected':'' ]">0%</option>
                                                <option value="5.00">5%</option>
                                                <option value="10.00">10%</option>
                                                <option value="20.00">20%</option>
                                              </select>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>                                                                            
                                        <b-row class="ml-4 mt-4">
                                          <b-col sm="8">
                                            <b-form-group label-for="total">
                                              <template #label>
                                                Total (con/sin Descuento)
                                              </template>
                                                $ {{  data.total_con_descuento == 0 ? data.total_con_descuento = data.total_dinero : data.total_con_descuento = (data.total_dinero - ((data.total_dinero * data.descuento)/100)).toFixed(2) }}
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </b-col>                                                           
                                    </b-row>
                                    <br><br>
                                    <br><br>
                                  </b-col>
                                </b-row>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <!-- === FIN DE OPERADORES === -->

                  <!-- === PRODUCTO TERMINADO === -->
                  <div class="container">
                    <b-row class="mt-4">
                      <div class="container">
                        <div class="row">
                          <div class="col-5" id="linea">
                            <hr>
                          </div>
                          <div class="col-2" id="titulo">
                            <p>Producto terminado</p>
                          </div>
                          <div class="col-5" id="linea">
                            <hr>
                          </div>
                        </div>
                      </div>
                    </b-row>
                    <b-row>
                      <b-col sm="12">
                        <div class="accordion" role="tablist">
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                              <b-button id="button_accordion" block v-b-toggle="'accordion-producto-terminado'+index_Q_P_T" variant="info">Producto Terminado: {{item_producto_terminado.descripcion}}</b-button>
                              <i class="fa fa-trash" @click="DeleteSelectedProductoTerminado(index_Q_P_T)" style="cursor: pointer;position: absolute;float: left;left: 90%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                              <i class="fa fa-save" @click="SaveItemProductoTerminado(index_Q_P_T)" style="cursor: pointer;position: absolute;float: left;left: 93%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                            </b-card-header>
                            <b-collapse :id="'accordion-producto-terminado'+index_Q_P_T" accordion="my-accordion-producto-terminado" role="tabpanel">
                              <b-card-body>
                                <br><br>
                                <b-row class="ml-4 mt-12">
                                  <b-col sm="12">
                                    <b-row style="padding: 1%;width: 99%;border-width: 1px;border-style: solid;border-color: #d0d0d0;border-radius: 5px;"> 
                                      <b-col sm="12">
                                        <b-form-group>
                                          <template #label>
                                            ESPECIFICACIONES
                                          </template>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2">
                                        <b-form-group>
                                          <template #label>
                                            Código
                                          </template>
                                          <b-form-input
                                            disabled
                                            v-model="item_producto_terminado.codigo"
                                            size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col> 
                                      <b-col sm="2">
                                        <b-form-group>
                                          <template #label>
                                            Descripción
                                          </template>
                                          <b-form-input
                                            disabled
                                            v-model="item_producto_terminado.descripcion"
                                            size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2">
                                        <b-form-group>
                                          <template #label>
                                            Comentarios
                                          </template>
                                          <b-form-input
                                            v-model="item_producto_terminado.comentarios"
                                            size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2">
                                        <b-form-group>
                                          <template #label>
                                            Cantidad
                                          </template>
                                          <b-form-input
                                            v-model="item_producto_terminado.no_piezas"
                                            size="sm"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2">
                                        <b-form-group label-for="largo_solicitado">
                                          <template #label>
                                            Costo unitario
                                          </template>
                                          <b-input-group size="sm" prepend="$">
                                            <b-form-input
                                              disabled
                                              v-model="item_producto_terminado.costo_unitario"
                                              size="sm"
                                            ></b-form-input>
                                          </b-input-group>
                                        </b-form-group>
                                      </b-col>
                                      <b-col sm="2">
                                        <b-form-group label-for="largo_solicitado" prepend="$">
                                          <template #label>
                                            Importe
                                          </template>
                                          <b-input-group size="sm" prepend="">
                                            <b-form-input
                                              disabled
                                              input-group-text="$"
                                              v-model="item_producto_terminado.importe"
                                              size="sm"
                                            ></b-form-input>
                                          </b-input-group>
                                        </b-form-group>
                                      </b-col>
                                      <br><br>
                                    </b-row>
                                    <br><br>
                                    <br><br>
                                  </b-col>
                                </b-row>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <!-- === FIN DE PRODUCTO TERMINADO -->

                </b-row>
              </div>

              <div  class="block-content block-content-full text-right border-top">
                <b-button variant="danger" class="mr-1" @click="DeleteSelectedProductoTerminado(index_Q_P_T)">Borrar NP</b-button>
              </div>

            </div>
            <br />
          </form>
        </div>

        <b-row class="mt-2">
          <b-col class="block-content">
            <table class="table table table-striped">
              <thead>
                <tr>
                  <td>
                    <h4 class="mb-0">UNITARIO</h4>
                  </td>
                  <td>
                    <h4 class="mb-0">TOTAL</h4>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p class="mb-0">COSTO UNITARIO: <b>$ {{ numberFormat.format(totalSinUtilidad) }}</b></p>
                  </td>
                  <td>
                    <p class="mb-0">COSTO TOTAL: <b>$ {{ numberFormat.format(totalSinUtilidadPorTotalDePiezas) }}</b></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p class="mb-0">PV UNITARIO: <b>$ {{ numberFormat.format(totalConUtilidad) }}</b></p>
                  </td>
                  <td>
                    <p class="mb-0">PV TOTAL: <b>$ {{ numberFormat.format(totalConUtilidadPorTotalDePiezas) }}</b></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p class="mb-0">UTILIDAD BRUTA: <b>$ {{ numberFormat.format(utilidadBruta) }}</b></p>
                  </td>
                  <td>
                    <p class="mb-0">UTILIDAD BRUTA TOTAL: <b>$ {{ numberFormat.format(totalUtilidadBruta) }}</b></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p class="mb-0">UTILIDAD BRUTA SOBRE VENTA %: <b>{{ utilidadBrutaPorcentaje }}</b></p>
                  </td>
                  <td>
                    <p class="mb-0">UTILIDAD BRUTA SOBRE VENTA %: <b>{{ totalUtilidadBrutaPorcentaje }}</b></p>
                  </td>
                </tr>
              </tbody>
            </table>
            
          </b-col>

          <b-col class="block-content mt-2">
            
          </b-col>
        </b-row>

        <div  class="block-content block-content-full text-right border-top">
          <b-button variant="alt-primary" class="mr-1" @click="Cancelar()">Cancelar</b-button>
          <b-button variant="primary" class="mr-1" :disabled="form.num_piezas=='' ? 'disabled': false" @click="updateQuote()">Guardar</b-button>
          <a class="btn btn-primary mr-1" :href="hostEndPointURL+'quote/pdf/'+quote_id" target="_blank">Imprimir cotización</a>
          <b-button variant="primary" v-if="form.estado_cotizacion === 'aceptado' && idOS == null" @click="createSO()">Guardar y generar orden de servicio</b-button>
          <b-button variant="primary" v-if="idOS != null" @click="viewSO()">Ver orden de servicio</b-button>
        </div>
      </base-block>
    </div>
  </div>
</template>

<script>

  import Api from "../../../../api/Api"
  import Servicios from "../../../../api/admin/services/Services";
  import Clientes from "../../../../api/admin/clientes/Clientes";
  import MateriaP from "../../../../api/admin/materia_prima/Materia_Prima";
  import Maquinarias from "../../../../api/admin/maquinaria/Maquinarias";
  import Empleados from "../../../../api/admin/empleados/Empleados";
  import Producto_Terminado from "../../../../api/admin/producto_terminado/Producto_Terminado";
  import PreCotizacion from "../../../../api/admin/cotizacion/Pre_cotizacion";
  //import Cotizacion from "../../../../api/admin/cotizacion/Cotizacion";
  import MateriaPrima from "../../../../api/admin/producto_terminado/materia_prima/MateriaPrima";
  import Maquinaria from "../../../../api/admin/producto_terminado/maquinaria/Maquinaria";
  import Operador from "../../../../api/admin/producto_terminado/operador/Operador";
  import OrdenServicio from "../../../../api/admin/orden_servicio/Orden_Servicio";
  import QuoteProductoTerminado from "../../../../api/admin/cotizacion/producto_terminado/ProductoTerminado"
  import Swal from "sweetalert2";
  import FileSystem from "../../../../components/FileSystem.vue"

  export default {

    components: {
      FileSystem
    },

    data() {
      return {
        searchTerm:'',
        quote_id:0,
        itemQFPIndex:0,
        hostEndPointURL:Api.defaults.appBaseURL,
        nuevaCotizacion:false,
        cotizarPorPV:true,
        cotizarPorPVServicios:true,
        cotizarPorPVOperadores:true,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        select_option:'',
        servicios:[],
        materiap:[],
        clientes:[],
        maquinariasList:[],
        operadoresList:[],
        productoTerminadoList:[],
        cotizacionInfo:[],
        currentQuote:0,
        totalRows:0,
        files:[],
        form: {
            cotizacion_id:'',
            comentarios:'',
            descripcion:'',
            estado_cotizacion:'',
            subTotal:'',
            descuento:0,
            iva:'',
            total:0,
        },              
        fields_servicios: [
          {key: 'id', label: '# ID'},
          {key: 'nombre', label: 'NOMBRE'},
          {key: 'costo_ferrebaztan', label: 'COSTO'},
          {key: 'margen', label: 'MARGEN'},
          {key: 'pv', label: 'PRECIO VENTA'},
        ],
        fields_materiap: [
          {key: 'codigo',sortable: true, label: 'CÓDIGO MACRO'},
          {key: 'descripcion',sortable: true, label: 'DESCRIPCIÓN'},
          {key: 'materia_prima_family', formatter: value => {
              return value.name
          },sortable: true, label: 'FAMILIA'},
          {key: 'materia_prima_unit', formatter: value => {
              return value.name
          },sortable: true, label: 'UNIDAD'},
          {key: 'service_type', formatter: value => {
              return value.nombre.length ? value.nombre : 'No especificado'
          },sortable: true, label: 'SERVICIO'},
          {key: 'peso_teorico',sortable: true, label: 'PESO TEORICO'},
          {key: 'maximo',sortable: true, label: 'MÁXIMO'},
          {key: 'minimo',sortable: true, label: 'MÍNIMO'},
        ],
        fields_maquinaria_list: [
          {key: 'numero_maquina',sortable: true, label: 'NÚMERO DE MAQUINA'},
          {key: 'nombre_maquina',sortable: true, label: 'NOMBRE DE LA MÁQUINA'},
          {key: 'costo_hora',sortable: true, label: 'COSTO HORA'}
        ],
        fields_operador_list: [
          {key: 'no_empleado',sortable: true, label: 'NÚMERO DE OPERADOR'},
          {key: 'nombre_completo',sortable: true, label: 'NOMBRE COMPLETO'},
          {key: 'salario_diario',sortable: true, label: 'SALARIO DIARIO'},
          {key: 'salario_hora',sortable: true, label: 'SALARIO HORA'}
        ],
        fields_producto_terminado_list: [
          {key: 'codigo', sortable: true, label: 'CÓDIGO'},
          {key: 'descripcion', sortable: true, label: 'DESCRIPCIÓN'},
          {key: 'kgs_procesados', sortable: true, label: 'KGS PROCESADOS'},
          {key: 'finished_product_family', formatter: value => {
                return value.name.length ? value.name : 'No especificado'
            },sortable: true, label: 'FAMILIA'},
          {key: 'finished_product_unit', formatter: value => {
                return value.name.length ? value.name : 'No especificado'
            },sortable: true, label: 'UNIDAD'},
          {key: 'maximo',sortable: true, label: 'MÁXIMO'},
          {key: 'minimo',sortable: true, label: 'MÍNIMO'},
          {key: 'actions',sortable: true, label: 'ACCIONES'},
        ],
        select_list_items_servicios: [],
        select_list_items_materiap: [],
        select_list_items_maquinaria: [],
        select_list_items_operadores: [],
        select_list_items_producto_terminado: [],
        selected_list_items_producto_terminado:[],
        optionsMoneyFormat:{ style: 'currency', currency: 'MXN' },
        numberFormat:new Intl.NumberFormat('es-MX', this.optionsMoneyFormat),
        idOS:null
      }
    },

    computed: {

      materiapFilter(){
        return this.materiap.filter((item) => {
          if (this.searchTerm === "") {
            return item;
          } else if ( 
            item.codigo.toLowerCase().includes(this.searchTerm.toLowerCase()) || 
            item.descripcion.toLowerCase().includes(this.searchTerm.toLowerCase())
          ) {
            return item;
          }
        })
      },

      maquinariasListFilter(){
        return this.maquinariasList.filter((item) => {
          if (this.searchTerm === "") {
            return item;
          } else if ( 
            item.nombre_maquina.toLowerCase().includes(this.searchTerm.toLowerCase())
          ) {
            return item;
          }
        })
      },

      operadoresListFilter(){
        return this.operadoresList.filter((item) => {
          if (this.searchTerm === "") {
            return item;
          } else if ( 
            item.no_empleado.toString().includes(this.searchTerm.toLowerCase()) ||
            item.nombre_completo.toLowerCase().includes(this.searchTerm.toLowerCase())
          ) {
            return item;
          }
        })
      },

      productoTerminadoListFilter(){
        return this.productoTerminadoList.filter((item) => {
          if (this.searchTerm === "") {
            return item;
          } else if ( 
            item.codigo.toLowerCase().includes(this.searchTerm.toLowerCase()) || 
            item.descripcion.toLowerCase().includes(this.searchTerm.toLowerCase())
          ) {
            return item;
          }
        })
      },

      totalSinUtilidad(){
        /**
        * Primera parte: "Materia prima"
        */
        let totalMateriaPrimaSinUtilidad = 0
        let totalMateriaPrimaSinUtilidadPorPieza = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.materia_prima[0].forEach(item_materia_prima => {
            totalMateriaPrimaSinUtilidad = (item_materia_prima.total_de_kilos * item_materia_prima.costo) / producto_terminado.no_piezas
            totalMateriaPrimaSinUtilidadPorPieza += totalMateriaPrimaSinUtilidad
          })
          /*if(producto_terminado.materia_prima[0].length > 0){
            totalMateriaPrimaSinUtilidadPorPieza += totalMateriaPrimaSinUtilidad
          }*/
        })
        //return totalMateriaPrimaSinUtilidadPorPieza

        /**
        * Segunda parte: "Maquinaria"
        */
        let totalMaquinariaSinUtilidad = 0
        let totalMaquinariaSinUtilidadPorPieza = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.maquinaria[0].forEach(maquina => {
            totalMaquinariaSinUtilidad = ((maquina.minutos/60) * maquina.costo) / producto_terminado.no_piezas
            totalMaquinariaSinUtilidadPorPieza += totalMaquinariaSinUtilidad
          })
          /*if(producto_terminado.maquinaria[0].length > 0){
            totalMaquinariaSinUtilidadPorPieza += totalMaquinariaSinUtilidad
          }*/
        })
        //return totalMaquinariaSinUtilidadPorPieza

        /*
        * Tercera parte: "Operadores"
        */

        let totalOperadoresSinUtilidad = 0
        let totalOperadoresSinUtilidadPorPieza = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.operadores[0].forEach(operador => {
            totalOperadoresSinUtilidad = ((operador.minutos/60) * operador.costo) / producto_terminado.no_piezas
            totalOperadoresSinUtilidadPorPieza += totalOperadoresSinUtilidad
          })
          /*if(producto_terminado.operadores[0].length > 0){
            totalOperadoresSinUtilidadPorPieza += totalOperadoresSinUtilidad
          }*/
        })
        //return totalOperadoresSinUtilidadPorPieza

        /**
        * Al final, lo que tenemos que retornar es la suma de las anteriores
        */

        return (totalMateriaPrimaSinUtilidadPorPieza + totalMaquinariaSinUtilidadPorPieza + totalOperadoresSinUtilidadPorPieza).toFixed(2)
      },

      totalConUtilidad(){
        /**
        * Primera parte: "Materia prima"
        */
        let totalMateriaPrimaConUtilidad = 0
        let totalMateriaPrimaConUtilidadPorPieza = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.materia_prima[0].forEach(item_materia_prima => {
            totalMateriaPrimaConUtilidad = item_materia_prima.total_con_descuento / producto_terminado.no_piezas
            console.log("totales de materia prima por producto terminado: ",totalMateriaPrimaConUtilidad)
            totalMateriaPrimaConUtilidadPorPieza += totalMateriaPrimaConUtilidad
          })

          /*if(producto_terminado.materia_prima[0].length > 0){
            totalMateriaPrimaConUtilidadPorPieza += totalMateriaPrimaConUtilidad
          }*/
        })
        // return totalMateriaPrimaConUtilidadPorPieza

        /**
        * Segunda parte: "Maquinaria"
        */
        let totalMaquinariaConUtilidad = 0
        let totalMaquinariaConUtilidadPorPieza = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.maquinaria[0].forEach(maquina => {
            totalMaquinariaConUtilidad = maquina.total_con_descuento / producto_terminado.no_piezas
            totalMaquinariaConUtilidadPorPieza += totalMaquinariaConUtilidad
          })
          /*if(producto_terminado.maquinaria[0].length > 0){
            totalMaquinariaConUtilidadPorPieza += totalMaquinariaConUtilidad
          }*/
        })
        //return totalMaquinariaConUtilidadPorPieza

        /*
        * Tercera parte: "Operadores"
        */

        let totalOperadoresConUtilidad = 0
        let totalOperadoresConUtilidadPorPieza = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.operadores[0].forEach(operador => {
            totalOperadoresConUtilidad = operador.total_con_descuento / producto_terminado.no_piezas
            totalOperadoresConUtilidadPorPieza += totalOperadoresConUtilidad
          })
          /*if(producto_terminado.operadores[0].length > 0){
            totalOperadoresConUtilidadPorPieza += totalOperadoresConUtilidad
          }*/
        })
        //return totalOperadoresConUtilidadPorPieza

        /**
        * Al final, lo que tenemos que retornar es la suma de las anteriores
        */

        console.log("total con utilidad de materia prima: ", totalMateriaPrimaConUtilidadPorPieza)
        console.log("total con utilidad de maquinaria: ", totalMaquinariaConUtilidadPorPieza)
        console.log("total con utilidad de operadores: ", totalOperadoresConUtilidadPorPieza)

        return (totalMateriaPrimaConUtilidadPorPieza + totalMaquinariaConUtilidadPorPieza + totalOperadoresConUtilidadPorPieza).toFixed(2)
      },

      utilidadBruta(){
        return (this.totalConUtilidad - this.totalSinUtilidad).toFixed(2)
      },

      utilidadBrutaPorcentaje(){
        // return ((this.utilidadBruta / this.totalSinUtilidad) * 100).toFixed(2)
        return ( this.utilidadBruta * 100 / this.totalConUtilidad ).toFixed(2)
      },

      totalSinUtilidadPorTotalDePiezas(){
        /**
        * Primera parte: "Materia prima"
        */
        let totalMateriaPrimaSinUtilidad = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.materia_prima[0].forEach(item_materia_prima => {
            totalMateriaPrimaSinUtilidad += (item_materia_prima.total_de_kilos * item_materia_prima.costo)
          })
        })

        // return totalMateriaPrimaSinUtilidadPorPieza

        /**
        * Segunda parte: "Maquinaria"
        */
        let totalMaquinariaSinUtilidad = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.maquinaria[0].forEach(maquina => {
            totalMaquinariaSinUtilidad += (maquina.minutos/60) * maquina.costo
          })
        })

        // return totalMaquinariaSinUtilidadPorPieza


        /**
        * Tercera parte: "Operadores"
        */
        let totalOperadoresSinUtilidad = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.operadores[0].forEach(operador => {
            totalOperadoresSinUtilidad += (operador.minutos/60) * operador.costo
          })
        })

        console.log("total sin utilidad de materia prima: ", totalMateriaPrimaSinUtilidad)
        console.log("total sin utilidad de maquinaria: ", totalMaquinariaSinUtilidad)
        console.log("total sin utilidad de operadores: ", totalOperadoresSinUtilidad)

        return (totalMateriaPrimaSinUtilidad + totalMaquinariaSinUtilidad + totalOperadoresSinUtilidad).toFixed(2)

      },

      totalConUtilidadPorTotalDePiezas(){
        /**
        * Primera parte: "Materia prima"
        */
        let totalMateriaPrimaConUtilidad = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.materia_prima[0].forEach(item_materia_prima => {
            totalMateriaPrimaConUtilidad += parseFloat(item_materia_prima.total_con_descuento)
          })
        })
        // return totalMateriaPrimaConUtilidad


        /**
        * Segunda parte: "Maquinaria"
        */
        let totalMaquinariaConUtilidad = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.maquinaria[0].forEach(maquina => {
            totalMaquinariaConUtilidad += parseFloat(maquina.total_con_descuento)
          })
        })

        // return totalMaquinariaConUtilidad


        /**
        * Tercera parte: "Operadores"
        */
        let totalOperadoresConUtilidad = 0
        this.selected_list_items_producto_terminado.forEach(producto_terminado => {
          producto_terminado.operadores[0].forEach(operador => {
            totalOperadoresConUtilidad += parseFloat(operador.total_con_descuento)
          })
        })

        //return totalOperadoresConUtilidad

        return (totalMateriaPrimaConUtilidad + totalMaquinariaConUtilidad + totalOperadoresConUtilidad).toFixed(2)

      },

      totalUtilidadBruta(){
        return (this.totalConUtilidadPorTotalDePiezas - this.totalSinUtilidadPorTotalDePiezas).toFixed(2)
      },

      totalUtilidadBrutaPorcentaje(){
        // return ((this.totalUtilidadBruta / this.totalSinUtilidadPorTotalDePiezas) * 100).toFixed(2)
        return ( this.totalUtilidadBruta * 100 / this.totalConUtilidadPorTotalDePiezas ) .toFixed(2)
      }

    },

    methods: {

      totalDeCostoUnitario(index){
        const total_materia_prima =  this.selected_list_items_producto_terminado[index].materia_prima[0].reduce(function (acc, item) {
          return acc += parseFloat(item.total_con_descuento)          
        }, 0);

        const total_maquinaria =  this.selected_list_items_producto_terminado[index].maquinaria[0].reduce(function (acc, item) {
          return acc += parseFloat(item.total_con_descuento)
        }, 0);


        const total_operadores =  this.selected_list_items_producto_terminado[index].operadores[0].reduce(function (acc, item) {
          return acc += parseFloat(item.total_con_descuento)
        }, 0);

        return total_materia_prima + total_maquinaria + total_operadores
      },

      async updateMaquinariaPV(pv,costo_hora) {
        
        setTimeout(function(){
            if(pv === ""){
              console.log("updateMaquinariaPV")
              console.log(costo_hora)
              return costo_hora;
            } else {
              return 0;
            }
        },2000);

      },

      Cancelar(){
        this.$router.push({
          path: "/cotizacion/list"
        });
      },

      async GetQuoteInfo(id){
        const context = this;
        let auth = JSON.parse(localStorage.autentication);
        await PreCotizacion.GetQuot(id,auth)
          .then((response) => {
            const responseQuoteInfo = response.data.data
            console.log(responseQuoteInfo)
            context.form.cotizacion_id = responseQuoteInfo.id
            context.form.client_id = responseQuoteInfo.client_id
            context.form.comentarios = responseQuoteInfo.comentarios
            context.form.estado_cotizacion = responseQuoteInfo.estado
            context.files = responseQuoteInfo.files
            /**
             * esta version es la que considera:
             * quote->materia_prima
             *      ->maquinaria
             *      ->operadores
             */
            //context.select_list_items_materiap = responseQuoteInfo.service_materia_prima
            //context.select_list_items_maquinaria = responseQuoteInfo.machineries
            //context.select_list_items_operadores = responseQuoteInfo.collaborators
            /**
             * esta version (la nueva, la 2.0) es la que considera
             * quote->producto_terminado->materia_prima
             *                          ->maquinaria
             *                          ->operadores
             */
            context.selected_list_items_producto_terminado = responseQuoteInfo.finished_products
            context.idOS = responseQuoteInfo.service_order.id
          })
          .catch((error) => {
          console.log(error.data);
          });
      },

      AllClientes() {
        let auth = JSON.parse(localStorage.autentication);
        Clientes.GetAllCustomers(auth)
          .then((response) => {
          this.clientes = response.data;
          })
          .catch((error) => {
          console.log(error.data);
          });
      },

      ChangePreCotizacion(event){
        let cliente = event.target.value
        let auth = JSON.parse(localStorage.autentication);
        PreCotizacion.CreateQuot(cliente, auth)
        .then((response) => {
          this.form.cotizacion_id = response.data.data.id;
          this.quote_id = response.data.data.id;
          this.nuevaCotizacion = false
          localStorage.id_cotizacion = this.form.cotizacion_id;
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
              Swal.fire({
                title: "Que mal!",
                text: elements[1][0],
                icon: "error",
              })
          );
        });
      },

      /**
      * Actualizar item de materia prima en producto terminado (Quote_Finished_Product_Service_Materia_Prima)
      * 
      * 
      * 
      * @param {Number} index_Q_P_T       Índice del item de producto terminado, perteneciente a la cotización en curso
      * @param {Number} index_Q_P_T_S_M_P Índice del item de materia prima del producto terminado en cuestión
      * @param {Number} id_Q_P_T          id de item del producto terminado, perteneciente a la cotización en curso
      * @param {Number} id_Q_P_T_S_M_P    id del item de la materia prima del producto termiando en cuestión
      *
      * @return {void}
      */

      UpdateItemMateriaPrima(index_Q_P_T, index_Q_P_T_S_M_P, id_Q_P_T, id_Q_P_T_S_M_P){

        console.log("hay que actualizar materia prima!! " + id_Q_P_T_S_M_P)

        console.log("índice de cotizacion_producto_terminado: "+index_Q_P_T)

        console.log("índice de cotizacion_producto_terminado_service_materia_prima; "+index_Q_P_T_S_M_P)

        console.log("id de quote_producto_terminado: "+id_Q_P_T)

        console.log("id de quote_producto_terminado_service_materia_prima: "+id_Q_P_T_S_M_P)

        this.selected_list_items_producto_terminado[index_Q_P_T].importe = this.totalDeCostoUnitario(index_Q_P_T)
        
        this.SaveItemProductoTerminado(index_Q_P_T)



        /**
        * Hasta el momento, tenemos los elementos necesarios para proceder a la actualización
        * vamos a analizar nuestro endpoint para determinar los ajustes a realizar
        * pues como mínimo y de hecho más importante, hay que considerar que se puede enviar el id del producto terminado
        * y que más adelante tendremos el problema de ver qué pasa cuando no haya un id de producto terminado por agregar
        * lo anterior pasará cuando se agregue un nuevo producto terminado
        */

        let auth = JSON.parse(localStorage.autentication);

        MateriaPrima.UpdateItemQuotFPMateriaPrima(JSON.stringify(this.selected_list_items_producto_terminado[index_Q_P_T].materia_prima[0][index_Q_P_T_S_M_P]),auth)
        .then((response) => {
         console.log("Update materia prima: "+response.data.message)
         this.GetQuoteInfo(this.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },


      /**
      * Actualizar item de materia prima en producto terminado (Quote_Finished_Product_Service_Materia_Prima)
      * 
      * 
      * 
      * @param {Number} index_Q_P_T       Índice del item de producto terminado, perteneciente a la cotización en curso
      * @param {Number} index_Q_P_T_M Índice del item de materia prima del producto terminado en cuestión
      * @param {Number} id_Q_P_T          id de item del producto terminado, perteneciente a la cotización en curso
      * @param {Number} id_Q_P_T_M    id del item de la materia prima del producto termiando en cuestión
      *
      * @return {void}
      */

      UpdateItemMaquinaria(index_Q_P_T, index_Q_P_T_M, id_Q_P_T, id_Q_P_T_M){

        console.log("hay que actualizar maquinaria!! "+id_Q_P_T_M)

        console.log("índice de cotizacion_producto_terminado: "+index_Q_P_T)

        console.log("índice de cotizacion_producto_terminado_maquinaria: "+index_Q_P_T_M)

        console.log("id de quote_producto_terminado: "+id_Q_P_T)

        console.log("id de quote_producto_terminado_maquinaria: "+id_Q_P_T_M)

        console.log("objeto en cuestion de producto terminado: "+this.selected_list_items_producto_terminado[index_Q_P_T])

        console.log("objeto en cuestion de quote->producto_terminado->maquinaria")

        console.log(this.selected_list_items_producto_terminado[index_Q_P_T].maquinaria[0][index_Q_P_T_M])

        console.log(Maquinaria)

        this.selected_list_items_producto_terminado[index_Q_P_T].importe = this.totalDeCostoUnitario(index_Q_P_T)
        
        this.SaveItemProductoTerminado(index_Q_P_T)

        let auth = JSON.parse(localStorage.autentication);
        Maquinaria.UpdateItemQuotFPMaquinaria(JSON.stringify(this.selected_list_items_producto_terminado[index_Q_P_T].maquinaria[0][index_Q_P_T_M]),auth)
        .then((response) => {
          console.log("respuesta de maquinaria updata: "+response.message)
          this.GetQuoteInfo(this.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });

      },

      UpdateItemOperador(index_Q_P_T, index_Q_P_T_O, id_Q_P_T, id_Q_P_T_O){

        console.log("índice de cotizacion_producto_terminado: "+index_Q_P_T)

        console.log("índice de cotizacion_producto_terminado_maquinaria: "+index_Q_P_T_O)

        console.log("id de quote_producto_terminado: "+id_Q_P_T)

        console.log("id de quote_producto_terminado_maquinaria: "+id_Q_P_T_O)

        console.log("objeto en cuestion de producto terminado: "+this.selected_list_items_producto_terminado[index_Q_P_T])

        console.log("objeto en cuestion de quote->producto_terminado->maquinaria")

        console.log(this.selected_list_items_producto_terminado[index_Q_P_T].operadores[0][index_Q_P_T_O])

        console.log(Operador)

        this.selected_list_items_producto_terminado[index_Q_P_T].importe = this.totalDeCostoUnitario(index_Q_P_T)
        
        this.SaveItemProductoTerminado(index_Q_P_T)

        let auth = JSON.parse(localStorage.autentication);
        Operador.UpdateItemQuotFPOperador(JSON.stringify(this.selected_list_items_producto_terminado[index_Q_P_T].operadores[0][index_Q_P_T_O]),auth)
        .then((response) => {
          console.log("Respuesta de update operador: "+response.message)
          this.GetQuoteInfo(this.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });

      },

      CreateItemQuoteProductoTerminado(itemIndex){
        const context = this

        // Cuando nosotros recuperamos los datos de esta lista
        // solo nos recupera la información el item
        // pero recordemos, que para ingresar un nuevo item a nuestra tabla
        // quot_producto_terminado
        // requerimos los datos de:
        // 1. quote_id
        // 2. finished_product_id
        // por lo que el cuerpo de nuestra petición debe tener algo así:

        let data = {
          no_piezas:this.selected_list_items_producto_terminado[itemIndex].no_piezas,
          no_parte:this.selected_list_items_producto_terminado[itemIndex].no_parte,
          quote_id:context.quote_id,
          finished_product_id:this.selected_list_items_producto_terminado[itemIndex].finished_product_id,
          cantidad:parseFloat(this.selected_list_items_producto_terminado[itemIndex].no_piezas),
          comentarios:this.selected_list_items_producto_terminado[itemIndex].comentarios,
          costo_unitario:parseFloat(this.selected_list_items_producto_terminado[itemIndex].importe / this.selected_list_items_producto_terminado[itemIndex].no_piezas ),
          importe:(parseFloat(context.selected_list_items_producto_terminado[itemIndex].importe))
        }

        console.log("intentanto guardar")

        console.log(data)

        data = JSON.stringify(data)
        let auth = JSON.parse(localStorage.autentication);
        
        QuoteProductoTerminado.CreateItemQuotProductoTerminado(data,auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          context.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          console.log(obj)
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      UpdateItemQuoteProductoTerminado(itemIndex){
        let auth = JSON.parse(localStorage.autentication);
        const context = this
        let data = {
          id:context.selected_list_items_producto_terminado[itemIndex].q_f_p_id,
          no_piezas:this.selected_list_items_producto_terminado[itemIndex].no_piezas,
          no_parte:this.selected_list_items_producto_terminado[itemIndex].no_parte,
          quote_id:context.quote_id,
          finished_product_id:context.selected_list_items_producto_terminado[itemIndex].finished_product_id,
          comentarios:context.selected_list_items_producto_terminado[itemIndex].comentarios,
          cantidad:parseFloat(context.selected_list_items_producto_terminado[itemIndex].no_piezas),
          costo_unitario:parseFloat(context.selected_list_items_producto_terminado[itemIndex].importe / this.selected_list_items_producto_terminado[itemIndex].no_piezas),
          importe:(parseFloat(context.selected_list_items_producto_terminado[itemIndex].importe))
        }
        QuoteProductoTerminado.UpdateItemQuotProductoTerminado(data,auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      SelectItemsServicios(data_select){
        let me = this
        if (me.select_list_items_servicios == '') {                    
          localStorage.lista_items_servicios = JSON.stringify(data_select);
          let data = JSON.parse(localStorage.lista_items_servicios)
          me.select_list_items_servicios.push({
              name :  data.nombre,
              pv: data.pv
          });
          me.$bvModal.hide("modal-items"); 
          me.select_option = '';
        }else{
          me.select_list_items_servicios.filter(function(element) {
            //[{2},{3}]         2
            if(element.id == data_select.id){
              Swal.fire({
                title: "Que mal!",
                text: 'Ya está agregado a la lista',
                icon: "error",
              });
            }else{
              me.select_list_items_servicios.push({
                name :  data_select.nombre,
                pv : data_select.pv
              });
              localStorage.lista_items_servicios = me.select_list_items_servicios;
              console.log(me.select_list_items_servicios);
              me.$bvModal.hide("modal-items");  
              me.select_option = '';
            }
          });
          }
      },

      SelectItemsMateriaP(data_select){
        let me = this

        if (me.selected_list_items_producto_terminado[me.itemQFPIndex].materia_prima == '') {
          console.log("arreglo vacío: "+me.itemQFPIndex);
          localStorage.lista_items_materiap = JSON.stringify(data_select);
          //let data = JSON.parse(localStorage.lista_items_materiap)
          const costo = data_select.service_type.costo_ferrebaztan
          const margen = data_select.service_type.margen
          const pv = data_select.service_type.pv

          data_select = {...data_select,
            costo:costo,
            margen:margen,
            pv:pv,
            no_laminas_placas:0,
            largo_solicitado:0,
            ancho_solicitado:0,
            comentarios:'',
            total_con_descuento:0,
            descuento:0
          }

          me.selected_list_items_producto_terminado[me.itemQFPIndex].materia_prima[0].push(data_select)

          me.$bvModal.hide("modal-items"); 
          me.select_option = '';

        } else {
          console.log("arreglo lleno checando: "+me.itemQFPIndex);
          me.selected_list_items_producto_terminado[me.itemQFPIndex].materia_prima.filter(function(element) {
            console.log("arreglo lleno: ");
            for(let i=0; i<element.length; i++ ){
              if ( element[i].id == data_select.id ){
                Swal.fire({
                  title: "Que mal!",
                  text: 'Ya está agregado a la lista',
                  icon: "error",
                });
                me.$bvModal.hide("modal-items");
                me.select_option = '';
                return;
              } 
            }

            data_select = {...data_select,
              costo:'',
              margen:'',
              pv:'',
              no_laminas_placas:0,
              largo_solicitado:0,
              ancho_solicitado:0,
              comentarios:'',
              total_con_descuento:0,
              descuento:0
            }
            console.log("no deberias estar haciendo el push")
            me.selected_list_items_producto_terminado[me.itemQFPIndex].materia_prima[0].push(data_select);
            me.$bvModal.hide("modal-items");
            me.select_option = '';
          });
        }
      },

      SelectItemsMaquinaria(data_select){

        console.log(data_select)

        let me = this

        if(me.selected_list_items_producto_terminado[me.itemQFPIndex].maquinaria == ''){
          console.log("arreglo vacío de producto terminado en su campo de maquinaria en el índice: "+me.itemQFPIndex);

          const costo = data_select.costo_hora

          data_select = {...data_select,
            costo:costo,
            margen:0,
            pv:costo,
            minutos:0,
            descuento:"0.00",
          }

          me.selected_list_items_producto_terminado[me.itemQFPIndex].maquinaria[0].push(data_select)

          me.$bvModal.hide("modal-items"); 
          me.select_option = '';

        } else {
          console.log("arreglo no vacío de producto terminado en su campo de maquinaria en el índice:  "+me.itemQFPIndex);

          me.selected_list_items_producto_terminado[me.itemQFPIndex].maquinaria.filter(function(element) {
            console.log("arreglo lleno: ");
            for(let i=0; i<element.length; i++ ){
              if ( element[i].id == data_select.id ){
                Swal.fire({
                  title: "Que mal!",
                  text: 'Ya está agregado a la lista',
                  icon: "error",
                });
                me.$bvModal.hide("modal-items");
                me.select_option = '';
                return;
              } 
            }

            console.log("no deberias estar haciendo el push")

            const costo = data_select.costo_hora

            data_select = {...data_select,
              costo:costo,
              margen:0,
              pv:costo,
              minutos:0,
              descuento:"0.00",
            }

            me.selected_list_items_producto_terminado[me.itemQFPIndex].maquinaria[0].push(data_select);
            me.$bvModal.hide("modal-items");
            me.select_option = '';
          });
        }

      },

      SelectItemsOperadores(data_select){
        let me = this

        console.log(data_select)

        if(me.selected_list_items_producto_terminado[me.itemQFPIndex].operadores == ''){
          console.log("arreglo vacío de producto terminado en su campo de operadores en el índice: "+me.itemQFPIndex);

          const costo = data_select.costo_hora

          data_select = {...data_select,
            costo:costo,
            margen:0,
            pv:costo,
            minutos:0,
            descuento:"0.00",
          }

          me.selected_list_items_producto_terminado[me.itemQFPIndex].operadores[0].push(data_select)

          me.$bvModal.hide("modal-items"); 
          me.select_option = '';

        } else {
          console.log("arreglo no vacío de producto terminado en su campo de operadores en el índice:  "+me.itemQFPIndex);

          me.selected_list_items_producto_terminado[me.itemQFPIndex].operadores.filter(function(element) {

            for(let i=0; i<element.length; i++ ){
              if ( element[i].id == data_select.id ){
                Swal.fire({
                  title: "Que mal!",
                  text: 'Ya está agregado a la lista',
                  icon: "error",
                });
                me.$bvModal.hide("modal-items");
                me.select_option = '';
                return;
              } 
            }

            console.log("no deberias estar haciendo el push")

            const costo = data_select.costo_hora

            data_select = {...data_select,
              costo:costo,
              margen:0,
              pv:costo,
              minutos:0,
              descuento:"0.00",
            }

            me.selected_list_items_producto_terminado[me.itemQFPIndex].operadores[0].push(data_select)
            me.$bvModal.hide("modal-items");
            me.select_option = '';
          });
        }

      },

      SelectItemsProductoTerminado(data_select){
        let me = this
        
        const newQuoteFinishedProductItem = {
          id: data_select.id,
          codigo: data_select.codigo,
          descripcion: data_select.descripcion,
          kgs_procesados: data_select.kgs_procesados,
          no_piezas:'',
          no_parte:'',
          cantidad:'',
          costo_unitario:0.0,
          importe:0.0,
          comentarios:'',
          quote_id:this.quote_id,
          finished_product_id:data_select.id,
          materia_prima:[[]],
          maquinaria:[[]],
          operadores:[[]],
          pivot:{
            cantidad:'',
            comentarios:'',
            costo_unitario:0.0,
            importe:0.0
          }
        }

        const countPT = me.selected_list_items_producto_terminado.length

        if(countPT == 0){
          me.selected_list_items_producto_terminado.push(newQuoteFinishedProductItem)
          console.log("primera llamada")
          me.$bvModal.hide("modal-items-pf");
          me.select_option = '';
          return 
        }


        /*for(let i=0; i<countPT; i++){
          if(me.selected_list_items_producto_terminado[i].id == data_select.id){
            Swal.fire({
              title: "Que mal!",
              text: 'Ya está agregado a la lista',
              icon: "error",
            });
            me.$bvModal.hide("modal-items-pf");
            me.select_option = '';
            return
          }
        }*/

        me.$bvModal.hide("modal-items-pf");
        me.select_option = '';
        me.selected_list_items_producto_terminado.push(newQuoteFinishedProductItem)

      },

      DeletedSelectMateriaP(q_f_p_s_m_p_id, materiaPrimaArrayIndex, productoTerminadoArrayIndex){

        Swal.fire({
          title: "¿Esta seguro de eliminar de la lista?",
          text: "¿Eliminar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: "Sí, eliminar",
          cancelButtonText: "Cancelar",
        })
        .then(resultado => {
          if (resultado.value) {
            const context = this

            if(q_f_p_s_m_p_id > 0){
              console.log("Hay que desde la base de datos")
              let auth = JSON.parse(localStorage.autentication)
              MateriaPrima.DeleteItemQuotFPMateriaPrima(q_f_p_s_m_p_id, auth)
              .then((response) => {
                Swal.fire({
                  title: "Genial!",
                  text: response.data.message,
                  icon: "success",
                });
                context.GetQuoteInfo(context.quote_id)
              })
              .catch((error) => {
                const obj = error.response.data.errors;
                Object.entries(obj).forEach( elements => 
                  Swal.fire({
                    title: "Que mal!",
                    text: elements[1][0],
                    icon: "error",
                  })
                );
              });
            } else if (q_f_p_s_m_p_id == 0){
              try{
                context.selected_list_items_producto_terminado[productoTerminadoArrayIndex].materia_prima[0].splice(materiaPrimaArrayIndex,1)
              } catch (e) {
                console.log(e)
              }
            } else {
              try{
                context.selected_list_items_producto_terminado[productoTerminadoArrayIndex].materia_prima[0].splice(materiaPrimaArrayIndex,1)
              } catch (e) {
                console.log(e)
              }
            }

          } else {
            // Dijeron que no
            console.log("*NO se elimina la lista*");
          }
        });
        
      },

      DeleteSelectedMaquinaria(q_f_p_m_id, maquinariaArrayIndex, productoTerminadoArrayIndex){
        console.log("id de la materia prima: "+q_f_p_m_id)
        console.log("índice del array de la maquinaria: "+maquinariaArrayIndex)
        console.log("índice del array de producto terminado: "+productoTerminadoArrayIndex)

        Swal.fire({
          title: "¿Esta seguro de eliminar de la lista?",
          text: "¿Eliminar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: "Sí, eliminar",
          cancelButtonText: "Cancelar",
        })
        .then(resultado => {
          if (resultado.value) {
            const context = this

            if(q_f_p_m_id > 0){
              console.log("Hay que desde la base de datos")
              let auth = JSON.parse(localStorage.autentication)
              Maquinaria.DeleteItemQuotFPMaquinaria(q_f_p_m_id, auth)
              .then((response) => {
                Swal.fire({
                  title: "Genial!",
                  text: response.data.message,
                  icon: "success",
                });
                context.GetQuoteInfo(context.quote_id)
              })
              .catch((error) => {
                const obj = error.response.data.errors;
                Object.entries(obj).forEach( elements => 
                  Swal.fire({
                    title: "Que mal!",
                    text: elements[1][0],
                    icon: "error",
                  })
                );
              });
            } else if (q_f_p_m_id == 0){
              try{
                context.selected_list_items_producto_terminado[productoTerminadoArrayIndex].maquinaria[0].splice(maquinariaArrayIndex,1)
              } catch (e) {
                console.log(e)
              }
            } else {
              try{
                context.selected_list_items_producto_terminado[productoTerminadoArrayIndex].maquinaria[0].splice(maquinariaArrayIndex,1)
              } catch (e) {
                console.log(e)
              }
            }

          } else {
            // Dijeron que no
            console.log("*NO se elimina la lista*");
          }
        });

      },

      DeleteSelectedOperador(q_f_p_o_id, operadoresArrayIndex, productoTerminadoArrayIndex){
        console.log("id del registro de producto termiado -> operador: "+q_f_p_o_id)
        console.log("índice del array de la operador: "+operadoresArrayIndex)
        console.log("índice del array de producto terminado: "+productoTerminadoArrayIndex)

        Swal.fire({
          title: "¿Esta seguro de eliminar de la lista?",
          text: "¿Eliminar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: "Sí, eliminar",
          cancelButtonText: "Cancelar",
        })
        .then(resultado => {
          if (resultado.value) {
            const context = this

            if(q_f_p_o_id > 0){
              console.log("Hay que desde la base de datos")
              let auth = JSON.parse(localStorage.autentication)
              Operador.DeleteItemQuotFPOperador(q_f_p_o_id, auth)
              .then((response) => {
                Swal.fire({
                  title: "Genial!",
                  text: response.data.message,
                  icon: "success",
                });
                context.GetQuoteInfo(context.quote_id)
              })
              .catch((error) => {
                const obj = error.response.data.errors;
                Object.entries(obj).forEach( elements => 
                  Swal.fire({
                    title: "Que mal!",
                    text: elements[1][0],
                    icon: "error",
                  })
                );
              });
            } else if (q_f_p_o_id == 0){
              try{
                context.selected_list_items_producto_terminado[productoTerminadoArrayIndex].operadores[0].splice(operadoresArrayIndex,1)
              } catch (e) {
                console.log(e)
              }
            } else {
              try{
                context.selected_list_items_producto_terminado[productoTerminadoArrayIndex].operadores[0].splice(operadoresArrayIndex,1)
              } catch (e) {
                console.log(e)
              }
            }

          } else {
            // Dijeron que no
            console.log("*NO se elimina la lista*");
          }
        });
      },

      DeletedSelectServicios(data){
        Swal.fire({
          title: "¿Esta seguro de eliminar de la lista?",
          text: "¿Eliminar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: "Sí, eliminar",
          cancelButtonText: "Cancelar",
        })
        .then(resultado => {
          if (resultado.value) {
            this.select_list_items_servicios.splice(data, 1);
          } else {
            // Dijeron que no
            console.log("*NO se elimina la lista*");
          }
        });
      },

      DeleteSelectedProductoTerminado(index){
        Swal.fire({
          title: "¿Esta seguro de eliminar de la lista?",
          text: "¿Eliminar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: "Sí, eliminar",
          cancelButtonText: "Cancelar",
        })
        .then(resultado => {
          if (resultado.value) {
            if(typeof this.selected_list_items_producto_terminado[index].id !== "undefined" && this.selected_list_items_producto_terminado[index].id > 0){
              // eliminar de la base de datos
              console.log(this.selected_list_items_producto_terminado[index].id)
              let auth = JSON.parse(localStorage.autentication);
              QuoteProductoTerminado.DeleteItemQuotProductoTerminado(this.selected_list_items_producto_terminado[index].id, auth)
              .then((response) => {
                Swal.fire({
                  title: "Genial!",
                  text: response.data.message,
                  icon: "success",
                });
                this.GetQuoteInfo(this.quote_id)
              })
              .catch((error) => {
                const obj = error.response.data.errors;
                console.log(obj)
                Object.entries(obj).forEach( elements => 
                  Swal.fire({
                    title: "Que mal!",
                    text: elements[1][0],
                    icon: "error",
                  })
                );
              });
            } else {
              this.selected_list_items_producto_terminado.splice(index, 1)
            }
          } else {
            // Dijeron que no
            console.log("*NO se elimina la lista*");
          }
        });
      },

      SaveItemProductoTerminado(itemIndex){
        const context = this
        
        let itemToSave = context.selected_list_items_producto_terminado[itemIndex]

        if(typeof itemToSave.q_f_p_id === 'undefined' || itemToSave.q_f_p_id == 0){
          // significa que no tiene la información del pivot (por lo que es entonces un producto nuevo)
          // y entonces hay que guardarlo
          context.CreateItemQuoteProductoTerminado(itemIndex)
        } else {
          context.UpdateItemQuoteProductoTerminado(itemIndex)
        }

      },

     /**
      * Agregar item de materia prima en producto terminado (Quote_Finished_Product_Service_Materia_Prima)
      * 
      * 
      * 
      * @param {Number} index_Q_P_T       Índice del item de producto terminado, perteneciente a la cotización en curso
      * @param {Number} index_Q_P_T_S_M_P Índice del item de materia prima del producto terminado en cuestión
      * @param {Number} id_Q_P_T          id de item del producto terminado, perteneciente a la cotización en curso
      *
      * @return {void}
      */

      SaveItemMateriaPrima(index_Q_P_T, index_Q_P_T_S_M_P, id_Q_P_T){

        const context = this

        console.log("hay que guardar nuevo en materia prima!!")

        console.log(id_Q_P_T)

        /**
        * Cuando vamos a agregar una nueva materia priam
        * tenemos que considerar que se tiene que agregar a nuestro array
        * de producto_termiando -> materia_prima.
        * Pero en sí lo que buscarmos hacer es insertar en la tabla
        * quote_finished_product_service_materia_prima
        * donde de entre todos los datos, lo importante son los siguientes:
        * q_f_p_id
        * m_p_id
        * s_id 
        */

        /**
        * vamos a por el momento solo a ver qué tenemos disponible en el objeto que podemos insertar... 
        */
        console.log(context.selected_list_items_producto_terminado[index_Q_P_T].materia_prima[0][index_Q_P_T_S_M_P])

        const m_p_id = context.selected_list_items_producto_terminado[index_Q_P_T].materia_prima[0][index_Q_P_T_S_M_P].id

        const s_id = context.selected_list_items_producto_terminado[index_Q_P_T].materia_prima[0][index_Q_P_T_S_M_P].service_id

        const q_f_p_id = id_Q_P_T

        context.selected_list_items_producto_terminado[index_Q_P_T].materia_prima[0][index_Q_P_T_S_M_P]["m_p_id"] = m_p_id
        context.selected_list_items_producto_terminado[index_Q_P_T].materia_prima[0][index_Q_P_T_S_M_P]["s_id"] = s_id
        context.selected_list_items_producto_terminado[index_Q_P_T].materia_prima[0][index_Q_P_T_S_M_P]["q_f_p_id"] = q_f_p_id


        console.log("mi id de materia prima: "+m_p_id)

        console.log("mi id de servicio de materia prima: "+s_id)

        console.log("mi id de producto terminado: "+q_f_p_id)

        console.log(context.selected_list_items_producto_terminado[index_Q_P_T].materia_prima[0][index_Q_P_T_S_M_P])

        const final_object = context.selected_list_items_producto_terminado[index_Q_P_T].materia_prima[0][index_Q_P_T_S_M_P]

        /**
        * Hasta este punto, ya tenemos nuestro objeto formado, debemos tomar en cuenta que es necesario el renombremienteo de los campos:
        * m_p_id
        * s_id
        * q_f_p_id
        * Por que esos campos son lo que espera recibir nuestro controlador, ahora si vamos a invocar a nuestro controlador haber que onda ...
        */

        context.selected_list_items_producto_terminado[index_Q_P_T].importe = context.totalDeCostoUnitario(index_Q_P_T)

        context.SaveItemProductoTerminado(index_Q_P_T)

        let auth = JSON.parse(localStorage.autentication);

        MateriaPrima.CreateItemQuotFPMateriaPrima(JSON.stringify(final_object), auth)
        .then((response) => {
          console.log("Respuesta de create materia prima: "+response.message)
          context.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });

      },

      /**
      * Agregar item de maquinaria en producto terminado (Quote_Finished_Product_Service_Materia_Prima)
      * 
      * 
      * 
      * @param {Number} index_Q_P_T       Índice del item de producto terminado, perteneciente a la cotización en curso
      * @param {Number} index_Q_P_T_M     Índice del item de maquinaria del producto terminado en cuestión
      * @param {Number} id_Q_P_T          id de item del producto terminado, perteneciente a la cotización en curso
      *
      * @return {void}
      */

      SaveItemMaquinaria(index_Q_P_T, index_Q_P_T_M, id_Q_P_T){
        const context = this

        console.log("hay que guardar nuevo en maquinaria!!")

        console.log(id_Q_P_T)

        /**
        * Cuando vamos a agregar una nueva maquinaria
        * tenemos que considerar que se tiene que agregar a nuestro array
        * de producto_termiando -> maquinaria.
        * Pero en sí lo que buscarmos hacer es insertar en la tabla
        * quote_finished_product_machineries
        * donde de entre todos los datos, lo importante son los siguientes:
        * q_f_p_id
        * machinery_id
        */

        /**
        * vamos a por el momento solo a ver qué tenemos disponible en el objeto que podemos insertar... 
        */
        console.log(context.selected_list_items_producto_terminado[index_Q_P_T].maquinaria[0][index_Q_P_T_M])

        const machinery_id = context.selected_list_items_producto_terminado[index_Q_P_T].maquinaria[0][index_Q_P_T_M].id

        const q_f_p_id = id_Q_P_T

        context.selected_list_items_producto_terminado[index_Q_P_T].maquinaria[0][index_Q_P_T_M]["machinery_id"] = machinery_id
        context.selected_list_items_producto_terminado[index_Q_P_T].maquinaria[0][index_Q_P_T_M]["q_f_p_id"] = q_f_p_id
        context.selected_list_items_producto_terminado[index_Q_P_T].maquinaria[0][index_Q_P_T_M]["total"] = context.selected_list_items_producto_terminado[index_Q_P_T].maquinaria[0][index_Q_P_T_M].total_dinero
        context.selected_list_items_producto_terminado[index_Q_P_T].maquinaria[0][index_Q_P_T_M]["corte_por_pieza"] = context.selected_list_items_producto_terminado[index_Q_P_T].maquinaria[0][index_Q_P_T_M].total_x_pieza

        console.log("mi id de maquinaria: "+machinery_id)

        console.log("mi id de producto terminado: "+q_f_p_id)

        console.log(context.selected_list_items_producto_terminado[index_Q_P_T].maquinaria[0][index_Q_P_T_M])

        const final_object = context.selected_list_items_producto_terminado[index_Q_P_T].maquinaria[0][index_Q_P_T_M]

        /**
        * Hasta este punto, ya tenemos nuestro objeto formado, debemos tomar en cuenta que es necesario el renombremienteo de los campos:
        * machinery_id
        * q_f_p_id
        * Por que esos campos son lo que espera recibir nuestro controlador, ahora si vamos a invocar a nuestro controlador haber que onda ...
        */


        context.selected_list_items_producto_terminado[index_Q_P_T].importe = context.totalDeCostoUnitario(index_Q_P_T)

        context.SaveItemProductoTerminado(index_Q_P_T)


        let auth = JSON.parse(localStorage.autentication);

        Maquinaria.CreateItemQuotFPMaquinaria(JSON.stringify(final_object), auth)
        .then((response) => {
          console.log("respuesta de create maquinaria: "+response.message)
          context.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      /**
      * Agregar item de maquinaria en producto terminado (Quote_Finished_Product_Service_Materia_Prima)
      * 
      * 
      * 
      * @param {Number} index_Q_P_T       Índice del item de producto terminado, perteneciente a la cotización en curso
      * @param {Number} index_Q_P_T_O     Índice del item de maquinaria del producto terminado en cuestión
      * @param {Number} id_Q_P_T          id de item del producto terminado, perteneciente a la cotización en curso
      *
      * @return {void}
      */

      SaveItemOperador(index_Q_P_T, index_Q_P_T_O, id_Q_P_T){
        const context = this

        console.log("hay que guardar nuevo en operador!!")

        /**
        * Cuando vamos a agregar un nuevo operador
        * tenemos que considerar que se tiene que agregar a nuestro array
        * de producto_termiando -> maquinaria.
        * Pero en sí lo que buscarmos hacer es insertar en la tabla
        * quote_finished_product_collaborators
        * donde de entre todos los datos, lo importante son los siguientes:
        * q_f_p_id
        * collaborator_id
        */

        /**
        * vamos a por el momento solo a ver qué tenemos disponible en el objeto que podemos insertar... 
        */
        console.log(context.selected_list_items_producto_terminado[index_Q_P_T].operadores[0][index_Q_P_T_O])

        const collaborator_id = context.selected_list_items_producto_terminado[index_Q_P_T].operadores[0][index_Q_P_T_O].id

        const q_f_p_id = id_Q_P_T

        context.selected_list_items_producto_terminado[index_Q_P_T].operadores[0][index_Q_P_T_O]["collaborator_id"] = collaborator_id
        context.selected_list_items_producto_terminado[index_Q_P_T].operadores[0][index_Q_P_T_O]["q_f_p_id"] = q_f_p_id
        context.selected_list_items_producto_terminado[index_Q_P_T].operadores[0][index_Q_P_T_O]["total"] = context.selected_list_items_producto_terminado[index_Q_P_T].operadores[0][index_Q_P_T_O].total_dinero
        context.selected_list_items_producto_terminado[index_Q_P_T].operadores[0][index_Q_P_T_O]["corte_por_pieza"] = context.selected_list_items_producto_terminado[index_Q_P_T].operadores[0][index_Q_P_T_O].total_x_pieza

        console.log("mi id de operador: "+collaborator_id)

        console.log("mi id de producto terminado: "+q_f_p_id)

        const final_object = context.selected_list_items_producto_terminado[index_Q_P_T].operadores[0][index_Q_P_T_O]

        console.log(final_object)

        /**
        * Hasta este punto, ya tenemos nuestro objeto formado, debemos tomar en cuenta que es necesario el renombremienteo de los campos:
        * machinery_id
        * q_f_p_id
        * Por que esos campos son lo que espera recibir nuestro controlador, ahora si vamos a invocar a nuestro controlador haber que onda ...
        */

        context.selected_list_items_producto_terminado[index_Q_P_T].importe = context.totalDeCostoUnitario(index_Q_P_T)

        context.SaveItemProductoTerminado(index_Q_P_T)

        let auth = JSON.parse(localStorage.autentication);

        Operador.CreateItemQuotFPOperador(JSON.stringify(final_object), auth)
        .then((response) => {
          console.log("Respuesta de create item operador: "+response.message)
          context.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },
      
      Option(event, itemQFPIndex) {
        let option = event.target.value
        let auth = JSON.parse(localStorage.autentication);

        this.itemQFPIndex = itemQFPIndex

        this.searchTerm = ""

        switch (parseInt(option)) {
          case 1:
            Servicios.GetAllServices(auth)
            .then((response) => {
              this.servicios = response.data;
              this.$bvModal.show("modal-items");
            })
            .catch((error) => {
              console.log(error.data);
            });
            break;

          case 2:
            MateriaP.GetAllMateriaPrima(auth)
            .then((response) => {
              this.materiap = response.data;
              this.$bvModal.show("modal-items");
              this.totalRows = this.materiap.length
            })
            .catch((error) => {
              console.log(error.data);
            });
            break;
          
          case 3:
            Maquinarias.GetAllMaquinas(auth)
            .then((response) => {
              this.maquinariasList = response.data;
              this.$bvModal.show("modal-items");
              this.totalRows = this.maquinariasList.length
            })
            .catch((error) => {
              console.log(error.data);
            });
            break;

          case 4:
            Empleados.GetAllEmpleados(auth)
            .then((response) => {
              this.operadoresList = response.data;
              this.$bvModal.show("modal-items");
              this.totalRows = this.operadoresList.length
            })
            .catch((error) => {
              console.log(error.data);
            });
            break;
          
          case 5:
            Producto_Terminado.GetAllProductoTerminado(auth)
            .then((response) => {
              this.productoTerminadoList = response.data;
              this.$bvModal.show("modal-items");
              this.totalRows = this.productoTerminadoList.length
            })
            .catch((error) => {
              console.log(error.data);
            });
            break;
        
          default:
            break;
        }

      },

      imprimirCotizacion(){
      },

      addNP(){

        let auth = JSON.parse(localStorage.autentication);

        // 1. Lo que debemos hacer es invocar al pop up de producto terminado
        Producto_Terminado.GetAllProductoTerminado(auth)
        .then((response) => {
          this.productoTerminadoList = response.data;
          this.$bvModal.show("modal-items-pf");
          this.totalRows = this.productoTerminadoList.length
        })
        .catch((error) => {
          console.log(error.data);
        });

        // 2. Cuando se abre el modal, podemos seleccionar nuestro producto terminado
        //    al seleccionar nuestro producto termiado, entonces se agrega al arreglo de selected_list_items_producto_terminado
        //    pero el tema es que no guarda en la base de datos quote_finished_products
        //    entonces lo que estoy pensando es que cuando agregamos en la funcion: SelectItemsProductoTerminado (linea 3100) 
        //    pues en ese momento se inserte en la base de datos...


        // cuando nosotros guardamos el NP lo que necesitamos agregar como base de quote_producto_terminado es lo siguiente:
        // una instancia de quote_finished_product que deberia tener al menos lo siguiente:
        // no_piezas
        // no_parte
        // cantidad
        // costo_unitario
        // importe
        // comentarios
        // quote_id
        // finished_product_id (que comenzaría con el valor null)
        // un arreglo vacío de materia prima
        // un arreglo vacío de maquinaria
        // un arreglo vacío de operadores

        /*const newQuoteFinishedProductItem = {
          no_piezas:'',
          no_parte:'',
          cantidad:'',
          costo_unitario:'',
          importe:'',
          comentarios:'',
          quote_id:this.quote_id,
          finished_product_id:null,
          materia_prima:[],
          maquinaria:[],
          operadores:[]
        }

        this.selected_list_items_producto_terminado.push(newQuoteFinishedProductItem)*/

      },

      updateQuote(){
        let data = {
          id: this.quote_id,
          estado: this.form.estado_cotizacion,
          comentarios: this.form.comentarios,
          descripcion: this.form.descripcion,
          subtotal:this.form.subtotal,
          descuento:this.form.descuento,
          iva:this.form.iva,
          total:this.totalConUtilidadPorTotalDePiezas
        }

        const context = this

        let auth = JSON.parse(localStorage.autentication);
        PreCotizacion.UpdateQuot(data, auth)
        .then((response) => {
          context.form.cotizacion_id = response.data.data.id;
          context.quote_id = response.data.data.id;
          context.nuevaCotizacion = false
          localStorage.id_cotizacion = context.form.cotizacion_id;
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          context.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
              Swal.fire({
                title: "Que mal!",
                text: elements[1][0],
                icon: "error",
              })
          );
        });
      },

      createSO(){

        const context = this

        const data = {
          quote_id:context.quote_id
        }

        let auth = JSON.parse(localStorage.autentication);

        OrdenServicio.CreateOrdenServicio(data , auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });

      },

      viewSO(){
        const context = this
        context.$router.push({
          path: "/ordenes-de-servicio/edit/"+context.quote_id,
          params:{
              id: context.idOS,
          }
        });
      }

    },

    mounted() {
      let me = this;
      const idForShow = me.$route.params.id
      me.quote_id = me.$route.params.id
      if(me.quote_id>0){
        me.nuevaCotizacion = false
        me.GetQuoteInfo(idForShow);
      } else {
        me.nuevaCotizacion = true
        me.form.cotizacion_id = '0'
      }
      me.AllClientes();
    }
  };



</script>


<style scoped>
    #button_accordion{
      background: #272e38;
      border-color: #272e38;
    }

    #titulo {
      text-align: center;
    }

    hr#linea {
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 0;
      border-top: 1px solid #030303;
    }

    hr{
      border-top: 1px solid #000000 !important;
    }

    .v-line{
      border-left: solid #e8dbdb;
      height: 57%;
      left: 63%;
      position: absolute;
    }

    .form-group{
      margin-bottom: 5px!important;
    }

</style>