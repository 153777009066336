import Api from "../../../Api";

const END_POINT_CREATE_ITEM_QUOT_FP_MATERIAPRIMA = 'user/quots/finished-product/materia-prima/create';
const END_POINT_UPDATE_ITEM_QUOT_FP_MATERIAPRIMA = 'user/quots/finished-product/materia-prima/update';
const END_POINT_DELETE_ITEM_QUOT_FP_MATERIAPRIMA = 'user/quots/finished-product/materia-prima/delete';

export default {

  CreateItemQuotFPMateriaPrima(data, auth){
    return Api.post(END_POINT_CREATE_ITEM_QUOT_FP_MATERIAPRIMA, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  UpdateItemQuotFPMateriaPrima(data, auth){
    return Api.patch(END_POINT_UPDATE_ITEM_QUOT_FP_MATERIAPRIMA, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  DeleteItemQuotFPMateriaPrima(id, auth){
    const options = {
      headers: {'Authorization': 'Bearer ' + auth.access_token},
      params: { id: id }
    }
    return Api.delete(END_POINT_DELETE_ITEM_QUOT_FP_MATERIAPRIMA,options);
  }
}