import Api from "../../../Api"

const END_POINT_CREATE_ITEM_QUOT_FP_MAQUINARIA = 'user/quots/finished-product/machinery/create';
const END_POINT_UPDATE_ITEM_QUOT_FP_MAQUINARIA = 'user/quots/finished-product/machinery/update';
const END_POINT_DELETE_ITEM_QUOT_FP_MAQUINARIA = 'user/quots/finished-product/machinery/delete';

export default {

  CreateItemQuotFPMaquinaria(data, auth){
    return Api.post(END_POINT_CREATE_ITEM_QUOT_FP_MAQUINARIA, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  UpdateItemQuotFPMaquinaria(data, auth){
    return Api.patch(END_POINT_UPDATE_ITEM_QUOT_FP_MAQUINARIA, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  DeleteItemQuotFPMaquinaria(id, auth){
    const options = {
      headers: {'Authorization': 'Bearer ' + auth.access_token},
      params: { id: id }
    }
    return Api.delete(END_POINT_DELETE_ITEM_QUOT_FP_MAQUINARIA,options);
  }
}