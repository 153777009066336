import Api from "../../../Api"

const END_POINT_CREATE_ITEM_QUOT_FP_OPERADOR = 'user/quots/finished-product/collaborator/create';
const END_POINT_UPDATE_ITEM_QUOT_FP_OPERADOR = 'user/quots/finished-product/collaborator/update';
const END_POINT_DELETE_ITEM_QUOT_FP_OPERADOR = 'user/quots/finished-product/collaborator/delete';

export default {

  CreateItemQuotFPOperador(data, auth){
    return Api.post(END_POINT_CREATE_ITEM_QUOT_FP_OPERADOR, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  UpdateItemQuotFPOperador(data, auth){
    return Api.patch(END_POINT_UPDATE_ITEM_QUOT_FP_OPERADOR, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  DeleteItemQuotFPOperador(id, auth){
    const options = {
      headers: {'Authorization': 'Bearer ' + auth.access_token},
      params: { id: id }
    }
    return Api.delete(END_POINT_DELETE_ITEM_QUOT_FP_OPERADOR,options);
  }
}