import Api from "../../../Api";

const END_POINT_CREATE_ITEM_QUOT_PRODUCTOTERMINADO = 'user/quots/quote-finished-product/create';
const END_POINT_UPDATE_ITEM_QUOT_PRODUCTOTERMINADO = 'user/quots/quote-finished-product/update';
const END_POINT_DELETE_ITEM_QUOT_PRODUCTOTERMINADO = 'user/quots/quote-finished-product/delete';

export default {

  CreateItemQuotProductoTerminado(data, auth){
    return Api.post(END_POINT_CREATE_ITEM_QUOT_PRODUCTOTERMINADO, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  UpdateItemQuotProductoTerminado(data, auth){
    return Api.patch(END_POINT_UPDATE_ITEM_QUOT_PRODUCTOTERMINADO, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  DeleteItemQuotProductoTerminado(id, auth){
    return Api.delete(END_POINT_DELETE_ITEM_QUOT_PRODUCTOTERMINADO + '/' + id, {
      headers: {
          'Authorization': 'Bearer ' + auth.access_token
      },
    })
  }
}